import React from "react";
import { Routes, Route } from "react-router-dom";
import { useGetAllCategoryQuery } from "../../../redux/categorySlice/categorySlice";
import CategoryList from "./CategoryList";
import EditCategory from "./EditCategory";

export default function Category() {
  const { data, isLoading } = useGetAllCategoryQuery();

  return (
    <div className="page-content">
      <Routes>
        <Route
          path="/"
          element={<CategoryList category={data} isLoading={isLoading} />}
        />
        <Route
          path=":id/edit"
          element={<EditCategory />}
          isLoading={isLoading}
        />
      </Routes>
    </div>
  );
}
