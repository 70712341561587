import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CloseButton,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";

import CustomButton from "../../common/buttons/CustomButton";
import { toast } from "react-toastify";
import {
  useDeleteProductVarationMutation,
  useDeleteSingleVarationValueMutation,
} from "../../../redux/varationSlice/varationSlice";
import DeleteModal from "../../common/deleteModel/DeleteModal";

function VariationForm({ variationToEdit, callback, isLoading, productId }) {
  const [variation, setVariation] = useState({
    name: "",
    value_type: "",
  });

  const [variationInfo, setVariationInfo] = useState([
    { value: "", extra_price: "" },
  ]);

  const [deleteModel, setDeleteModel] = useState(false);

  const [deleteVariation] = useDeleteProductVarationMutation();
  const [deleteSingleVaration] = useDeleteSingleVarationValueMutation();

  const onChange = (e) => {
    setVariation({ ...variation, [e.target.name]: e.target.value });
  };

  const addNewRow = () => {
    setVariationInfo([...variationInfo, { value: "", extra_price: "" }]);
  };

  useEffect(() => {
    if (variationToEdit) {
      setVariationInfo(variationToEdit?.product_variation_info);
      setVariation({
        name: variationToEdit.name,
        value_type: variationToEdit?.value_type,
      });
    }
  }, [variationToEdit]);

  const removeFormFields = (i) => {
    let newFormValues = [...variationInfo];
    let removedRow = newFormValues.splice(i, 1);
    if (removedRow[0].hasOwnProperty("id")) {
      deleteSingleVaration(removedRow[0].id);
    }
    setVariationInfo(newFormValues);
  };

  let handleChange = (i, e) => {
    const { name, value } = e.target;
    const old = variationInfo[i];
    const updated = { ...old, [name]: value };
    var list = [...variationInfo];
    list[i] = updated;
    setVariationInfo(list);
  };

  const handleDeleteProduct = () => {
    deleteVariation({ id: productId, varId: variationToEdit.id }).then((e) =>
      toast.success("Varation Deleted..")
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const to_server = {
      name: variation.name,
      item: productId,
      value_type: variation.value_type,
      product_variation_info: [...variationInfo],
    };

    callback({
      id: productId,
      data: to_server,
      varId: variationToEdit?.id,
    }).then(() => {
      setVariation(variationToEdit?.name ?? { name: "", value_type: "" });
      setVariationInfo(
        variationToEdit?.product_variation_info ?? [
          {
            value: "",
            extra_price: "",
          },
        ]
      );
      toast.success("Action Complated..");
    });
  };
  return (
    <form onSubmit={onSubmit}>
      <DeleteModal
        show={deleteModel}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModel(false)}
      />
      <div className="mb-3">
        <Label className="form-label" htmlFor="category-name-input">
          Variation Name
        </Label>
        <Input
          required
          type="text"
          className="form-control"
          id="variation-name-input"
          placeholder="Enter Variation Name"
          name="name"
          value={variation.name}
          onChange={onChange}
        />
      </div>

      <div className="mb-3">
        <Label className="form-label" htmlFor="varation-type-input">
          Variation Type
        </Label>
        <Input
          required
          type="select"
          className="form-select"
          id="varation-type-input"
          placeholder="Select Variation Type"
          name="value_type"
          value={variation.value_type}
          onChange={onChange}
        >
          <option>Select Varation Type</option>
          <option value="radio">Radio</option>
          <option value="select">Select</option>
        </Input>
      </div>
      <Card>
        <CardHeader>
          <h5 className="card-title mb-0 d-flex justify-content-between align-items-center">
            Varation Info
            <CustomButton
              onClick={addNewRow}
              color="link"
              text="Add New Values"
              type="button"
            />
          </h5>
        </CardHeader>
        <CardBody>
          {variationInfo.map((info, idx) => (
            <Row key={idx} className="mt-1 mb-1 align-items-center">
              <Col lg={1}>
                <CloseButton onClick={() => removeFormFields(idx)} />
              </Col>
              <Col lg={7}>
                <Input
                  name="value"
                  type="text"
                  className="form-input"
                  id="value-field"
                  placeholder="Value"
                  required
                  value={info.value}
                  onChange={(e) => handleChange(idx, e)}
                />
              </Col>
              <Col lg={4}>
                <Input
                  name="extra_price"
                  type="number"
                  className="form-input"
                  id="extra_value-field"
                  placeholder="Extra Price"
                  value={info.extra_price}
                  onChange={(e) => handleChange(idx, e)}
                />
              </Col>
            </Row>
          ))}
        </CardBody>
      </Card>

      <div className="text-end mb-3">
        <Row>
          {variationToEdit && (
            <Col>
              <button
                onClick={() => setDeleteModel(true)}
                type="button"
                className="btn btn-danger w-100"
              >
                Delete
              </button>
            </Col>
          )}
          <Col>
            <button
              disabled={isLoading}
              type="submit"
              className="btn btn-success w-100"
            >
              Submit
            </button>
          </Col>
        </Row>
      </div>
    </form>
  );
}

export default VariationForm;
