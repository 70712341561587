import React from "react";
import { Routes, Route } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import { dashboardRoutes } from "../Routes/Routes";

function DashboardPages() {
  return (
    <MainLayout>
      <Routes>
        {dashboardRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.component}
          ></Route>
        ))}
      </Routes>
    </MainLayout>
  );
}

export default DashboardPages;
