import React from "react";

function SearchProduct({ state, onChange }) {
  return (
    <div style={{ maxWidth: "300px" }}>
      <ul className="list-unstyled mb-0 filter-list">
        <li>
          <div className="search-box search-box-sm">
            <input
              style={{ borderRadius: "20px" }}
              type="text"
              className="form-control border-0 fs-9"
              name="name"
              value={state.name}
              onChange={onChange}
              placeholder="Search.."
            />
            <i className="ri-search-line search-icon"></i>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default SearchProduct;
