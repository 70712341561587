import React from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import CategoryTable from "../../../components/dashboard/category_table/CategoryTable";
import AddCategory from "../../../components/dashboard/add_category/AddCategory";
function CategoryList({ category, isLoading }) {
  return (
    <Container fluid>
      <BreadCrumb title="Categorys" pageTitle="Home" link="/admin" />
      <Card>
        <CardHeader className="border-0 rounded">
          <Row className="g-2 justify-content-end">
            <div className="col-lg-auto">
              <div className="hstack gap-2">
                <AddCategory btnColor="success" />
              </div>
            </div>
          </Row>
        </CardHeader>
      </Card>
      <Row className="justify-content-center">
        <Col xl={11} lg={11}>
          <CategoryTable categorys={category} />
        </Col>
      </Row>
    </Container>
  );
}

export default CategoryList;
