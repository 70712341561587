import React from "react";
import { Button } from "reactstrap";

function CustomButton({ text, onClick, color, btnClasses, ...props }) {
  return (
    <Button color={color} onClick={onClick} className={btnClasses} {...props}>
      {text}
    </Button>
  );
}

export default CustomButton;
