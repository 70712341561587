import React, { useState } from "react";
import { Input, Label } from "reactstrap";
import {
  useAddCategoryMutation,
  useGetAllCategoryQuery,
} from "../../../redux/categorySlice/categorySlice";
import { convertToSlug, filePreview } from "../../../utils/Utils";
import CustomModel from "../../common/customModel/CustomModel";
import { toast } from "react-toastify";
function AddCategory({ btnColor = "link" }) {
  const [category, setCategory] = useState({
    name: "",
    category_image: "",
    parent: "",
  });
  const onChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };

  const [add_category, { isLoading }] = useAddCategoryMutation();
  const { data, isLoading: isCategoryLodaing } = useGetAllCategoryQuery();

  const onSubmit = (e) => {
    e.preventDefault();
    const slug = convertToSlug(category.name);
    const formData = new FormData();
    formData.append("name", category.name);
    formData.append("category_image", category.category_image);
    formData.append("slug", slug);
    if (category.parent) {
      formData.append("parent", category.parent);
    } else {
      formData.append("parent", []);
    }

    add_category(formData)
      .unwrap()
      .then((e) => {
        toast.success("Category Add");
        setCategory({
          name: "",
          category_image: "",
          parent: "",
        });
      })
      .catch((e) => {
        for (var key in e.data) {
          toast.error(`${key} : ${e.data[key]}`);
        }
      });
  };
  return (
    <CustomModel
      btnText="Add New Category"
      btnColor={btnColor}
      title="Add New Category"
    >
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="category-title-input">
            Category Name
          </Label>
          <Input
            required
            type="text"
            className="form-control"
            id="category-title-input"
            placeholder="Enter Category Name"
            name="name"
            value={category.name}
            onChange={onChange}
          />
        </div>
        <div className="mb-4">
          <h5 className="fs-14 mb-1">Category Image</h5>
          <p className="text-muted">Add Category main Image.</p>
          <div className="text-center">
            <div className="position-relative d-inline-block">
              <div className="position-absolute top-100 start-100 translate-middle">
                <label
                  htmlFor="category-image-input"
                  className="mb-0"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title=""
                  data-bs-original-title="Select Image"
                >
                  <div className="avatar-xs">
                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                      <i className="ri-image-fill"></i>
                    </div>
                  </div>
                </label>
                <input
                  className="form-control d-none"
                  defaultValue=""
                  id="category-image-input"
                  type="file"
                  required
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "category_image",
                        value: e.target.files[0],
                      },
                    })
                  }
                  accept="image/png, image/gif, image/jpeg"
                />
              </div>
              <div className="avatar-lg">
                <div className="avatar-title bg-light rounded">
                  <img
                    src={
                      category.category_image &&
                      filePreview(category.category_image).preview
                    }
                    id="category-img"
                    alt=""
                    className="avatar-md h-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          {!data && isCategoryLodaing ? null : (
            <Input
              name="parent"
              type="select"
              className="form-select"
              id="parent-field"
              onChange={onChange}
              value={category.parent}
            >
              <option>Select Parent Category</option>
              {data.map((item, key) => (
                <option
                  value={item.name}
                  key={item.id}
                  className={`${item.parent && "text-muted"}`}
                >
                  {item.parent
                    ? `-- ${item.name}  (${item.parent})`
                    : item.name}
                </option>
              ))}
            </Input>
          )}
        </div>
        <div className="text-end mb-3">
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-success w-100"
          >
            Submit
          </button>
        </div>
      </form>
    </CustomModel>
  );
}

export default AddCategory;
