import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLayoutMode } from "../../../redux/layoutSlice/layoutSlice";

//constants
import { layoutModeTypes } from "../../../utils/layout";

const LightDark = () => {
  const dispatch = useDispatch();
  const { layoutModeType } = useSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
  }));

  const mode =
    layoutModeType === layoutModeTypes["DARKMODE"]
      ? layoutModeTypes["LIGHTMODE"]
      : layoutModeTypes["DARKMODE"];

  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  return (
    <div className="ms-1 header-item ">
      <button
        onClick={() => onChangeLayoutMode(mode)}
        type="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
      >
        <i className="bx bx-moon fs-22"></i>
      </button>
    </div>
  );
};

export default LightDark;
