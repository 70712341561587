import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

function HorizontalCard({ product }) {
  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );
  return (
    <Card className="rounded shadow-none card-bg ">
      <Row className="g-0">
        <Col xs={4}>
          <img
            className="rounded img-fluid object-cover min-h-100"
            src={product.product_image}
            alt="Card"
          />
        </Col>
        <Col xs={8}>
          <CardHeader className="d-flex justify-content-between align-items-center mb-0 pb-2 pt-3 border-0 card-bg  ">
            <h5 className="card-title mb-0 text-dark">{product.title}s</h5>
            <div className="text-start fw-bold">
              {product.discount_price ? (
                <>
                  <del className="text-muted fs-6 me-1 text-primary">
                    {product.price}
                  </del>
                  {product.discount_price}
                </>
              ) : (
                product.price
              )}
              {current_currency}
            </div>
          </CardHeader>
          <CardBody>
            <p className="card-text mb-2  w-100">{product.side_description}</p>

            <Link to={`/${product.slug}`} className="stretched-link"></Link>
          </CardBody>
        </Col>
      </Row>
    </Card>
  );
}

export default HorizontalCard;
