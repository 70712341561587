import React from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../common/buttons/CustomButton";
function WhatsappShare() {
  const { cart } = useSelector((state) => state.cart);
  let message = "*Hello This is My Order*\n\n";
  const onClick = (e) => {
    let url = `https://web.whatsapp.com/send?phone=+201020206268`;

    // Appending the message to the URL by encoding it

    cart.map((item) => {
      message += `*${item.title}*\n\n`;
      item.data.map((vars, idx) => {
        const keys = Object.keys(vars);
        const values = Object.values(vars);
        keys.map((key, keyIdx) => (message += `${key}: ${values[keyIdx]}\t`));
        message += `Quantity: ${item.qtyPerVars[idx]}\t Price: ${item.pricePerQty[idx]}\n\n`;
      });
    });
    url += `&text=${encodeURI(message)}&app_absent=0`;
    window.open(url);
    // console.log(message);
  };
  return (
    <div>
      <CustomButton
        onClick={onClick}
        color="success"
        btnClasses="d-flex justify-content-between mb-2"
        text={
          <>
            <i className="ri-whatsapp-fill fs-6 me-1" /> whatsapp
          </>
        }
      />
    </div>
  );
}

export default WhatsappShare;
