import { api } from "../api";

export const CouponApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCoupons: builder.query({
      query: () => "coupon/",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Coupons", id })),
              { type: "Coupons", id: "LIST" },
            ]
          : [{ type: "Coupons", id: "LIST" }],
    }),
    addCoupon: builder.mutation({
      query: (coupon) => ({
        url: "coupon/",
        method: "POST",
        body: coupon,
      }),
      invalidatesTags: [{ type: "Coupons", id: "LIST" }],
    }),
    editCoupon: builder.mutation({
      query: (coupon) => ({
        url: `coupon/${coupon.id}/`,
        method: "PUT",
        body: coupon,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Coupons", id: id }],
    }),
    deleteCoupon: builder.mutation({
      query: (id) => ({
        url: `coupon/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { parent }) => [
        { type: "Coupons", id: parent },
      ],
    }),
  }),
});

export const {
  useGetAllCouponsQuery,
  useEditCouponMutation,
  useDeleteCouponMutation,
  useAddCouponMutation,
} = CouponApi;
