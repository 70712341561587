import { combineReducers } from "@reduxjs/toolkit";
import { api } from "./api";
import authSlice from "./authSlice/authSlice";
import cartSlice from "./cart/cartSlice";
import layoutSlice from "./layoutSlice/layoutSlice";
import siteSettingSlice from "./siteSettingSlice/siteSettingSlice";

const reducers = combineReducers({
  [api.reducerPath]: api.reducer,
  Layout: layoutSlice,
  siteSetting: siteSettingSlice,
  auth: authSlice,
  cart: cartSlice,
});

export default reducers;
