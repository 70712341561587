import React from "react";
import { Col, Row } from "reactstrap";
import FilterProductModel from "../filterProductModel/FilterModel";
import LayoutView from "../layout-view/LayoutView";
import SearchProduct from "../search-product/SearchProduct";

function TopBar({ onChange, state, reset }) {
  return (
    <Row className="gx-0 mb-1  align-items-center ">
      <Col>
        <SearchProduct onChange={onChange} state={state} />
      </Col>

      <Col>
        <div className="d-flex justify-content-end mt-1">
          <FilterProductModel
            onChange={onChange}
            filter={state}
            reset={reset}
          />
          <LayoutView />
        </div>
      </Col>
    </Row>
  );
}

export default TopBar;
