import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col } from "reactstrap";

function CardWithTable({ children, title, link }) {
  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
        <div className="flex-shrink-0">
          <Link to={link}>
            <button type="button" className="btn btn-soft-secondary btn-sm">
              Show All
            </button>
          </Link>
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive table-card">
          <table className="table table-hover table-centered align-middle table-nowrap mb-0">
            <tbody>{children}</tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default CardWithTable;
