import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromCart } from "../../../redux/cart/cartSlice";

function SmallCartCard({ item }) {
  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );
  const dispatch = useDispatch();
  return (
    <>
      {item.data.map((vars, idx) => (
        <div
          className="d-block dropdown-item text-wrap dropdown-item-cart px-3 py-2"
          key={idx}
        >
          <div className="d-flex align-items-center">
            <img
              src={item.imgUrl}
              className="me-3 rounded-circle avatar-sm p-2 bg-light"
              alt="user-pic"
            />
            <div className="flex-1">
              <h6 className="mt-0 mb-1 fs-14">
                <Link to={`${item.slug}/`} className="text-reset">
                  {item.title}
                </Link>
              </h6>
              <p className="mb-0 fs-12 text-muted">
                Quantity:{" "}
                <span>
                  {parseFloat(item.pricePerQty[idx]).toFixed(2)}x
                  {item.qtyPerVars[idx]}
                </span>
              </p>
            </div>
            <div className="px-0">
              <h5 className="m-0 fw-normal">
                <span className="cart-item-price">
                  {parseFloat(item.pricePerQty[idx]).toFixed(2) *
                    item.qtyPerVars[idx]}
                </span>{" "}
                {current_currency}
              </h5>
            </div>
            <div className="ps-2">
              <button
                type="button"
                className="btn btn-icon btn-sm btn-ghost-secondary remove-item-btn"
                onClick={(e) => {
                  dispatch(removeFromCart({ id: item.id, data: vars }));
                }}
              >
                <i className="ri-close-fill fs-16"></i>
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default SmallCartCard;
