import React from "react";
import { Link } from "react-router-dom";

//import images
import logoSm from "../../../assets/images/logo-sm.png";
import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo-light.png";

import MyCartDropdown from "../../common/cart_dropdown/CartDropdown";

// import ProfileDropdown from "../profile_dropdown/ProfileDropdown";
import LightDark from "../../common/ligh_dark/LightDark";
import { useSelector } from "react-redux";

const TopBar = () => {
  const siteSetting = useSelector((state) => state.siteSetting.siteSetting);
  return (
    <React.Fragment>
      <header id="page-topbar" className="start-0 restaurant-nav">
        <div className="layout-width ">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={siteSetting.image} alt="" height="40" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={siteSetting.image}
                      alt=""
                      height="100%"
                      width="80"
                    />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={siteSetting.image} alt="" height="40" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={siteSetting.image}
                      alt=""
                      height="100%"
                      width="80"
                    />
                  </span>
                </Link>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <MyCartDropdown />
              <LightDark />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default TopBar;
