import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, ButtonGroup } from "reactstrap";
import { changeProductColumnsView } from "../../../redux/layoutSlice/layoutSlice";

function LayoutView() {
  const dispatch = useDispatch();
  const layout = useSelector((state) => state.Layout.productColumnsView);
  return (
    <ButtonGroup size="sm" className="ms-1">
      <Button
        color="primary"
        outline
        onClick={() => dispatch(changeProductColumnsView("towcolumn"))}
        active={layout === "towcolumn"}
      >
        <i className="bx bx-grid-alt fs-22"></i>
      </Button>
      <Button
        color="primary"
        outline
        onClick={() => dispatch(changeProductColumnsView("onecolumn"))}
        active={layout === "onecolumn"}
      >
        <i className="bx bx-list-ul fs-22"></i>
      </Button>
    </ButtonGroup>
  );
}

export default LayoutView;
