import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";
import { removeFromCart } from "../../../redux/cart/cartSlice";

function CartCard({ item }) {
  const dispatch = useDispatch();
  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );
  return (
    <>
      {item.data.map((vars, idx) => (
        <Card key={idx} className="product">
          <CardBody>
            <Row className="gy-3">
              <div className="col-sm-auto">
                <div className="avatar-lg bg-light rounded p-1">
                  <img
                    src={item.imgUrl}
                    alt=""
                    className="rounded img-fluid object-cover min-h-100"
                  />
                </div>
              </div>
              <div className="col-sm">
                <h5 className="fs-14 text-truncate">
                  <Link to={`/${item.slug}/`} className="text-reset">
                    {item.title}
                  </Link>
                </h5>
                <ul className="list-inline text-muted">
                  {Object.keys(vars).map((varName) => (
                    <li key={varName} className="list-inline-item">
                      {varName} :{" "}
                      <span className="fw-medium">{vars[varName]}</span>
                    </li>
                  ))}
                </ul>

                <p className="mb-0 fs-12 text-muted">
                  Quantity: <span>{item.qtyPerVars[idx]}</span>
                </p>
              </div>
              <div className="col-sm-auto">
                <div className="text-lg-end">
                  <p className="text-muted mb-1">Item Price:</p>
                  <h5 className="fs-14">
                    <span id="ticket_price" className="product-price">
                      {parseFloat(item.pricePerQty[idx]).toFixed(2)}
                    </span>{" "}
                    {current_currency}
                  </h5>
                </div>
              </div>
            </Row>
          </CardBody>

          <div className="card-footer">
            <div className="row align-items-center gy-3">
              <div className="col-sm">
                <div className="d-flex flex-wrap my-n1">
                  <div>
                    <Link
                      to="#"
                      className="d-block text-body p-1 px-2"
                      onClick={(e) => {
                        dispatch(
                          removeFromCart({
                            id: item.id,
                            data: vars,
                          })
                        );
                      }}
                    >
                      <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>{" "}
                      Remove
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-auto">
                <div className="d-flex align-items-center gap-2 text-muted">
                  <div>Total :</div>
                  <h5 className="fs-14 mb-0">
                    <span className="product-line-price">
                      {" "}
                      {parseFloat(item.pricePerQty[idx]).toFixed(2) *
                        item.qtyPerVars[idx]}
                    </span>{" "}
                    {current_currency}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </>
  );
}

export default CartCard;
