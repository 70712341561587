import React, { useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { useDeleteProductMutation } from "../../../redux/productsSlice/productsSlice";
import DeleteModal from "../../common/deleteModel/DeleteModal";
import CustomTable from "../../common/table/Table";
import { Price, Published, Rating } from "../../common/ValueDisplay";

function ProductTable({ products }) {
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [dele, setDele] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [product, setProduct] = useState(null);
  const [deleteProduct] = useDeleteProductMutation();

  const onClickDelete = (product) => {
    setProduct(product);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (product) {
      deleteProduct(product.id);
      setDeleteModal(false);
    }
  };

  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      deleteProduct(element.value);
      del.style.display = "none";
    });
  };

  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (cell) => {
          return (
            <input
              type="checkbox"
              className="productCheckBox"
              value={cell.row.original.id}
              onClick={() => displayDelete()}
            />
          );
        },
      },
      {
        Header: "Product",
        accessor: "title",
        Cell: (product) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={product.row.original.product_image}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  <Link
                    to={`/admin/products/${product.row.original.slug}`}
                    className="text-dark"
                  >
                    {" "}
                    {product.row.original.title}
                  </Link>
                </h5>
                <p className="text-muted mb-0">
                  Category :{" "}
                  <span className="fw-medium">
                    {" "}
                    {product.row.original.category}
                  </span>
                </p>
              </div>
            </div>
          </>
        ),
      },

      {
        Header: "Price",
        accessor: "price",
        filterable: false,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        },
      },
      {
        Header: "Discount Price",
        accessor: "discount_price",
        filterable: false,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        },
      },
      {
        Header: "Sales",
        accessor: "sales_count",
        filterable: false,
      },
      {
        Header: "Rating",
        accessor: "average_rating",
        filterable: false,
        Cell: (cellProps) => {
          return <Rating {...cellProps} />;
        },
      },
      {
        Header: "Published",
        accessor: "created_at",
        filterable: false,
        Cell: (cellProps) => {
          return <Published {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem>
                  <Link to={`/admin/products/${cellProps.row.original.slug}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to={`/admin/products/${cellProps.row.original.slug}/edit`}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </Link>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const productData = cellProps.row.original;
                    onClickDelete(productData);
                  }}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />

      <div>
        <div className="card">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="card-title mb-0 flex-grow-1">All Products</h4>
              </div>
              <div className="col-auto">
                <div id="selection-element">
                  <div className="my-n1 d-flex align-items-center text-muted">
                    Select{" "}
                    <div
                      id="select-content"
                      className="text-body fw-semibold px-1"
                    >
                      {dele}
                    </div>{" "}
                    Result{" "}
                    <button
                      type="button"
                      className="btn btn-link link-danger p-0 ms-3"
                      onClick={() => setDeleteModalMulti(true)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <CustomTable
              columns={columns}
              data={products || []}
              isGlobalFilter={true}
              isAddUserList={false}
              customPageSize={10}
              divClass="table-responsive mb-1"
              tableClass="mb-0 align-middle table-borderless"
              theadClass="table-light text-muted"
              isProductsFilter={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductTable;
