import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CustomButton from "../buttons/CustomButton";

function CustomModel({
  children,
  title,
  btnText,
  btnColor,
  btnClasses,
  btnSize = "md",
  closeFooter,
  footerCloseText,
  withBtn = true,
  show = false,
  customToggle,
}) {
  const [open, setOpen] = useState(false);

  function toggle() {
    setOpen(!open);
  }

  return (
    <>
      {withBtn && (
        <CustomButton
          onClick={toggle}
          text={btnText}
          color={btnColor}
          size={btnSize}
        />
      )}
      <Modal
        isOpen={withBtn ? open : show}
        toggle={() => {
          toggle();
        }}
        backdrop={"static"}
        id="staticBackdrop"
        centered
      >
        <ModalHeader
          className="modal-title"
          id="staticBackdropLabel"
          toggle={() => {
            if (customToggle) return customToggle();
            toggle();
          }}
        >
          {title}
        </ModalHeader>
        <ModalBody className=" p-4">{children}</ModalBody>
        {closeFooter && (
          <ModalFooter>
            <CustomButton
              onClick={toggle}
              text={footerCloseText}
              color="info"
              size="sm"
            />
          </ModalFooter>
        )}
      </Modal>
    </>
  );
}

export default CustomModel;
