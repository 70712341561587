import React from "react";
import { Swiper } from "swiper/react";

function MultiSlider({ children, ...rest }) {
  return (
    <Swiper {...rest} className="mySwiper">
      {children}
    </Swiper>
  );
}

export default MultiSlider;
