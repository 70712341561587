import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import { useSelector } from "react-redux";
import NotFound from "./components/common/notfound/NotFound";
defineLordIconElement(loadAnimation);

function App() {
  const { faill, loading } = useSelector((state) => state.siteSetting);

  if (loading) {
    return null;
  }

  return (
    <div className="App">
      <ToastContainer closeButton={false} />
      {faill ? (
        <NotFound url="" onClick={() => window.location.replace()} />
      ) : (
        <Routes />
      )}
    </div>
  );
}

export default App;
