import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import WhatsappShare from "../../../components/restaurant/whatsapp_share/WhatsappShare";
import CartCard from "../../../components/common/cards/CartCard";
import EmptyCard from "../../../components/restaurant/cart-empty/EmptyCard";
import ApplyCouponComponent from "../../../components/restaurant/apply-coupon/ApplyCoupon";

function Checkout() {
  const { current_currency, siteSetting } = useSelector(
    (state) => state.siteSetting
  );
  const { cart, total_price, cartLenght, coupon } = useSelector(
    (state) => state.cart
  );

  return (
    <main className="overflow-hidden restaurant-detail">
      <div className="page-content w-100">
        <Container fluid>
          <BreadCrumb pageTitle="Home" title="Checkout" link="/" />

          <Row className="mb-3">
            <Col xl={8}>
              <Row className="align-items-center gy-3 mb-3">
                <div className="col-sm">
                  <div>
                    <h5 className="fs-14 mb-0">
                      Your Cart ({cartLenght} items)
                    </h5>
                  </div>
                </div>
                <div className="col-sm-auto">
                  <Link
                    to="/"
                    className="link-primary text-dark text-decoration-underline"
                  >
                    Continue Shopping
                  </Link>
                </div>
              </Row>
              {cartLenght ? (
                cart.map((item, key) => <CartCard key={item.id} item={item} />)
              ) : (
                <EmptyCard />
              )}

              <div className="text-end mb-4 ">
                {siteSetting?.whatsapp_available && <WhatsappShare />}
              </div>
            </Col>

            <Col xl={4}>
              <div className="sticky-side-div">
                <Card>
                  <CardHeader className="border-bottom-dashed">
                    <h5 className="card-title mb-0">Order Summary</h5>
                  </CardHeader>
                  <ApplyCouponComponent />
                  <CardBody className="pt-2">
                    <div className="table-responsive">
                      <table className="table table-borderless mb-0">
                        <tbody>
                          {coupon && (
                            <tr>
                              <td>
                                Discount{" "}
                                <span className="text-muted">
                                  ({coupon.coupon_code})
                                </span>{" "}
                                :{" "}
                              </td>
                              <td className="text-end" id="cart-discount">
                                {coupon.coupon_type === "Fixed"
                                  ? `- ${coupon.value} ${current_currency}`
                                  : `- %${coupon.value}`}
                              </td>
                            </tr>
                          )}
                          <tr className="table-active">
                            <th>Total ({current_currency}) :</th>
                            <td className="text-end">
                              <span className="fw-semibold" id="cart-total">
                                {total_price} {current_currency}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
}

export default Checkout;
