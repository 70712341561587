import React from "react";
import { Col } from "reactstrap";
import product1 from "../../../assets/images/products/img-1.png";
import product2 from "../../../assets/images/products/img-2.png";
import product3 from "../../../assets/images/products/img-3.png";
import product4 from "../../../assets/images/products/img-4.png";
import product5 from "../../../assets/images/products/img-5.png";
import CardWithTable from "../../../components/common/cards/CardWithTable";
import ProductInTable from "../../../components/dashboard/product_in_table/ProductInTable";

const BestSellingProducts = () => {
  return (
    <Col xl={6}>
      <CardWithTable title="Best Selling Products" link="#">
        {(bestSellingProductsData || []).map((item, key) => (
          <ProductInTable key={key} item={item} />
        ))}
      </CardWithTable>
    </Col>
  );
};

export default BestSellingProducts;

const bestSellingProductsData = [
  {
    id: 1,
    img: product1,
    label: "Branded T-Shirts",
    date: "24 Apr 2021",
    price: 29.0,
    orders: 62,
    stock: 510,
    amount: 1798,
  },
  {
    id: 2,
    img: product2,
    label: "Bentwood Chair",
    date: "19 Mar 2021",
    price: 85.2,
    orders: 35,
    amount: 2982,
  },
  {
    id: 3,
    img: product3,
    label: "Borosil Paper Cup",
    date: "01 Mar 2021",
    price: 14.0,
    orders: 80,
    stock: 749,
    amount: 1120,
  },
  {
    id: 4,
    img: product4,
    label: "One Seater Sofa",
    date: "11 Feb 2021",
    price: 127.5,
    orders: 56,
    amount: 7140,
  },
  {
    id: 5,
    img: product5,
    label: "Stillbird Helmet",
    date: "17 Jan 2021",
    price: 54,
    orders: 74,
    stock: 805,
    amount: 3996,
  },
];
