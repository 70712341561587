import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  useDeleteCouponMutation,
  useEditCouponMutation,
} from "../../../redux/couponSlice/couponSlice";
import DeleteModal from "../../common/deleteModel/DeleteModal";
import CustomTable from "../../common/table/Table";
import { Published } from "../../common/ValueDisplay";
import AddCoupon from "../add_coupon/AddCoupon";

function CouponTable({ data }) {
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [dele, setDele] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [deleteCoupon] = useDeleteCouponMutation();
  const [editModal, setEditModal] = useState(false);
  const [couponEdit, setCouponEdit] = useState(null);
  const [editCoupon] = useEditCouponMutation();

  const onClickDelete = (product) => {
    setCoupon(product);
    setDeleteModal(true);
  };

  const handleDeleteCoupon = () => {
    if (coupon) {
      deleteCoupon(coupon.id);
      setDeleteModal(false);
    }
  };

  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      deleteCoupon(element.value);
      del.style.display = "none";
    });
  };

  const displayDelete = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    setDele(ele.length);
    if (ele.length === 0) {
      del.style.display = "none";
    } else {
      del.style.display = "block";
    }
  };
  const onEditClick = (coupon) => {
    setCouponEdit(coupon);
    setEditModal(true);
  };

  const handelUpdateCouponFinish = () => {
    setEditModal(false);
    setCouponEdit(null);
  };

  const onEditSubmit = (validation, data) => {
    editCoupon(data)
      .unwrap()
      .then((e) => {
        toast.success("Coupon Added..");
        validation.resetForm();
      })
      .catch((error) => {
        if (error.data) validation.setErrors(error.data);
        console.log(error);
      });
  };
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (cell) => {
          return (
            <input
              type="checkbox"
              className="productCheckBox"
              value={cell.row.original.id}
              onClick={() => displayDelete()}
            />
          );
        },
      },
      {
        Header: "Coupon",
        accessor: "coupon_code",
        Cell: (product) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  {product.row.original.coupon_code}
                </h5>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Type",
        accessor: "coupon_type",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Descraption",
        accessor: "descraption",
      },

      {
        Header: "Published",
        accessor: "created_at",
        filterable: false,
        Cell: (cellProps) => {
          return <Published {...cellProps} />;
        },
      },
      {
        Header: "Expired At",
        accessor: "valid_until",
        filterable: false,
        Cell: (cellProps) => {
          return <Published {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={(e) => onEditClick(cellProps.row.original)}
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                  Edit
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem
                  href="#"
                  onClick={() => {
                    const productData = cellProps.row.original;
                    onClickDelete(productData);
                  }}
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCoupon}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      <AddCoupon
        show={editModal}
        withBtn={false}
        updateObj={couponEdit}
        callback={handelUpdateCouponFinish}
        onSubmit={onEditSubmit}
      />

      <div>
        <div className="card">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="card-title mb-0 flex-grow-1">Coupons</h4>
              </div>
              <div className="col-auto">
                <div id="selection-element">
                  <div className="my-n1 d-flex align-items-center text-muted">
                    Select{" "}
                    <div
                      id="select-content"
                      className="text-body fw-semibold px-1"
                    >
                      {dele}
                    </div>{" "}
                    Result{" "}
                    <button
                      type="button"
                      className="btn btn-link link-danger p-0 ms-3"
                      onClick={() => setDeleteModalMulti(true)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <CustomTable
              columns={columns}
              data={data || []}
              isGlobalFilter={true}
              isAddUserList={false}
              customPageSize={10}
              divClass="table-responsive mb-1"
              tableClass="mb-0 align-middle table-borderless"
              theadClass="table-light text-muted"
              //   isProductsFilter={true}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CouponTable;
