import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

const BreadCrumb = ({ title, pageTitle, link }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center ">
              <Button
                onClick={() => navigate(-1)}
                className="btn btn-icon me-1 btn-light btn-outline-light rounded-circle"
              >
                <i className=" bx bx-left-arrow-alt fs-22 text-primary"></i>
              </Button>
              <h4 className="mb-sm-0">{title}</h4>
            </div>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to={`${link}`}>{pageTitle}</Link>
                </li>
                <li className="breadcrumb-item active">{title}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
