import React, { useState } from "react";

import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";

import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { filePreview } from "../../../utils/Utils";

import {
  useEditCategoryMutation,
  useGetAllCategoryQuery,
} from "../../../redux/categorySlice/categorySlice";
import { toast } from "react-toastify";

function EditCategory() {
  const { id } = useParams();

  const { data, isLoading: isCategoryLodaing } = useGetAllCategoryQuery();

  const getCurrentCategory = () => {
    let current;
    if (data) {
      data.map((parent) => {
        if (parent.slug === id) current = parent;
        else
          parent?.children.map((child) => {
            if (child.slug === id) current = child;
          });
      });
    }
    return current;
  };

  const category = getCurrentCategory();

  const [editCategory, { isLoading }] = useEditCategoryMutation();

  const [categoryImage, setCategoryImage] = useState(null);

  const onCategoryImageChange = (e) => {
    setCategoryImage(e.target.files[0]);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name,
      parent: category?.parent,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter a Product Title"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);

      if (categoryImage) {
        formData.append("category_image", categoryImage);
      }
      if (values.parent) {
        formData.append("parent", values.parent);
      } else {
        formData.append("parent", []);
      }

      editCategory({ id: category.id, body: formData })
        .unwrap()
        .then(() => {
          validation.resetForm();
          setCategoryImage(null);
          toast.success("Category Updated..");
        })
        .catch((e) => {
          for (var key in e.data) {
            toast.error(`${key} : ${e.data[key]}`);
          }
        });
    },
  });

  return (
    <Container fluid>
      <BreadCrumb
        title={category?.name}
        pageTitle="Categorys"
        link={`/admin/categorys/`}
      />
      <Row className="justify-content-center">
        <Col lg={9}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="Category-title-input">
                    Category Title
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="Category-title-input"
                    placeholder="Enter Category title"
                    name="name"
                    value={validation.values.name || ""}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.name && validation.touched.name
                        ? true
                        : false
                    }
                  />
                  {validation.errors.name && validation.touched.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-4">
                  <h5 className="fs-14 mb-1">Category Image</h5>
                  <p className="text-muted">Add Category main Image.</p>
                  <div className="text-center">
                    <div className="position-relative d-inline-block">
                      <div className="position-absolute top-100 start-100 translate-middle">
                        <label
                          htmlFor="category-image-input"
                          className="mb-0"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title=""
                          data-bs-original-title="Select Image"
                        >
                          <div className="avatar-xs">
                            <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                              <i className="ri-image-fill"></i>
                            </div>
                          </div>
                        </label>
                        <input
                          className="form-control d-none"
                          defaultValue=""
                          id="category-image-input"
                          type="file"
                          onChange={onCategoryImageChange}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div className="avatar-lg">
                        <div className="avatar-title bg-light rounded">
                          <img
                            src={
                              categoryImage
                                ? filePreview(categoryImage).preview
                                : category?.category_image
                            }
                            id="product-img"
                            alt=""
                            className="avatar-md h-auto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  {!data && isCategoryLodaing ? null : (
                    <Input
                      name="parent"
                      type="select"
                      className="form-select"
                      id="parent-field"
                      value={validation.values.parent || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.parent && validation.touched.parent
                          ? true
                          : false
                      }
                    >
                      <option value="">Select Parent Category</option>
                      {data.map((item, key) => (
                        <option
                          value={item.name}
                          key={item.id}
                          className={`${item.parent && "text-muted"}`}
                        >
                          {item.parent
                            ? `-- ${item.name}  (${item.parent})`
                            : item.name}
                        </option>
                      ))}
                    </Input>
                  )}
                </div>
              </CardBody>
            </Card>

            <div className="text-end mb-3">
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-success w-sm"
              >
                Submit
              </button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default EditCategory;
