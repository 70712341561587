import { api } from "../api";

export const categoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: () => "product/category/",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ slug }) => ({ type: "Category", slug })),
              { type: "Category", id: "LIST" },
            ]
          : [{ type: "Category", id: "LIST" }],
    }),
    addCategory: builder.mutation({
      query: (category) => ({
        url: "product/category/",
        method: "POST",
        body: category,
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    editCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `product/category/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { parent }) => [
        { type: "Category", id: parent },
      ],
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `product/category/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { parent }) => [
        { type: "Category", id: parent },
      ],
    }),
  }),
});

export const {
  useGetAllCategoryQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useEditCategoryMutation,
} = categoryApi;
