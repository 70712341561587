import React from "react";
import { Link } from "react-router-dom";
function OrderInTable({ item }) {
  return (
    <tr>
      <td>
        <Link
          to="/apps-ecommerce-order-details"
          className="fw-medium link-primary"
        >
          {item.orderId}
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center fs-14 my-1 fw-normal">
          <div className="flex-grow-1">{item.name}</div>
        </div>
      </td>
      <td>{item.product}</td>
      <td>
        <span className="text-success fs-14 my-1 fw-normal">
          ${item.amount}
        </span>
      </td>
      <td>
        <span className={"badge badge-soft-" + item.statusClass}>
          {item.status}
        </span>
      </td>
    </tr>
  );
}

export default OrderInTable;
