import React, { useState } from "react";
import { SwiperSlide } from "swiper/react";
import { useGetAllCategoryQuery } from "../../../redux/categorySlice/categorySlice";
import CategoryCard from "../../common/cards/CategoryCard";
import MultiSlider from "../../common/swiper/MultiSlider";
import All from "../../../assets/images/category/All.png";
import { Fade } from "reactstrap";

const AllCategory = {
  name: "",
  category_image: All,
};

function CategorySlider({ state, onChange }) {
  const { data = [], isLoading } = useGetAllCategoryQuery();
  const [activeCategory, setActiveCategory] = useState();

  if (isLoading) {
    return (
      <Fade>
        <span className="placeholder col-12 placeholder-lg mt-3 mb-3"></span>
      </Fade>
    );
  }
  return (
    <Fade>
      <MultiSlider
        slidesPerView={2.5}
        spaceBetween={10}
        breakpoints={{
          0: {
            slidesPerView: 1.5,
            spaceBetween: 5,
          },
          250: {
            slidesPerView: 2.5,
            spaceBetween: 5,
          },
          390: {
            slidesPerView: 3.5,
            spaceBetween: 3,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 6,
          },
        }}
      >
        <SwiperSlide onClick={() => setActiveCategory(null)}>
          <CategoryCard
            state={state}
            category={AllCategory}
            onChange={onChange}
          />
        </SwiperSlide>
        {data.map((category) => (
          <SwiperSlide
            onClick={() => setActiveCategory(category)}
            key={category.id}
          >
            <CategoryCard
              category={category}
              state={state}
              onChange={onChange}
            />
          </SwiperSlide>
        ))}
      </MultiSlider>

      <MultiSlider
        slidesPerView={2.5}
        spaceBetween={10}
        breakpoints={{
          0: {
            slidesPerView: 1.5,
            spaceBetween: 5,
          },
          250: {
            slidesPerView: 2.5,
            spaceBetween: 5,
          },
          390: {
            slidesPerView: 3.5,
            spaceBetween: 3,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
        }}
      >
        {activeCategory?.children.map((item) => (
          <SwiperSlide key={item.id}>
            <CategoryCard
              subCategory={true}
              category={item}
              state={state}
              onChange={onChange}
            />
          </SwiperSlide>
        ))}
      </MultiSlider>
    </Fade>
  );
}

export default CategorySlider;
