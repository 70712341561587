import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function RequireAuth({ children }) {
  const navigate = useNavigate();
  const { user, AuthSuccessLoading, isAuthenticated } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (AuthSuccessLoading) return null;
    if (!isAuthenticated) navigate("/login");
    return () => {};
  }, [AuthSuccessLoading, isAuthenticated, navigate]);

  if (user && (user?.is_staff || user?.is_superuser)) return children;
  return null;
}

export default RequireAuth;
