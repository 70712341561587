import React from "react";

function PricingWidget({ icon, label, detail }) {
  return (
    <div className="p-2 border border-dashed rounded">
      <div className="d-flex align-items-center">
        <div className="avatar-sm me-2">
          <div className="avatar-title rounded bg-transparent text-success fs-24">
            <i className={`${icon}`}></i>
          </div>
        </div>
        <div className="flex-grow-1">
          <p className="text-muted mb-1">{label} :</p>
          <h5 className="mb-0">{detail}</h5>
        </div>
      </div>
    </div>
  );
}

export default PricingWidget;
