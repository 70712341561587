import React, { useRef, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CustomButton from "../../common/buttons/CustomButton";
import "./product-varation.styles.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/cart/cartSlice";
import { isEmpty } from "lodash";
function ProductVarations({ product }) {
  const dispatch = useDispatch();
  const varationsRef = useRef(null);
  const [qty, setQty] = useState(1);
  const [total_price, setTotalPrice] = useState(
    product?.discount_price ? product.discount_price : product.price
  );
  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );

  const calcVarations = () => {
    let currentPrice = parseFloat(
      product?.discount_price ? product.discount_price : product.price
    );
    let data = {};

    if (varationsRef.current) {
      const checked = varationsRef.current.querySelectorAll(
        "input[type=radio]:checked"
      );
      const selected = varationsRef.current.querySelectorAll("select");
      for (let i = 0; i < checked.length; i++) {
        data[checked[i].name] = checked[i].value;
        if (checked[i].getAttribute("extra-price"))
          currentPrice += parseFloat(checked[i].getAttribute("extra-price"));
      }
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].value) data[selected[i].name] = selected[i].value;
        const extra =
          selected[i][selected[i].options.selectedIndex].getAttribute(
            "extra-price"
          );
        if (extra) currentPrice += parseFloat(extra);
      }
    }
    setTotalPrice(parseFloat(currentPrice).toFixed(2));
    return data;
  };

  const onChange = (e) => {
    calcVarations();
  };

  const countUp = () => {
    setQty((qty) => qty + 1);
  };
  const countDown = () => {
    if (qty === 1) {
      return;
    }
    setQty((qty) => qty - 1);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let vars = calcVarations();
    const toCart = {
      id: product.id,
      title: product.title,
      slug: product.slug,
      qtyPerVars: [parseInt(qty)],
      pricePerQty: [parseFloat(total_price).toFixed(2)],
      imgUrl: product.product_image,
      data: [vars],
    };
    dispatch(addToCart(toCart));
  };

  return (
    <div
      ref={varationsRef}
      className="product-detail-varation p-3 border-2 card-bg"
    >
      <Row className="border-2 gap-1">
        <Col xs={12}>
          <h3>{product.title}</h3>
        </Col>
        <Col xs={12}>
          <h6 className="text-primary fw-bold">
            {total_price} {current_currency}
          </h6>
        </Col>
      </Row>
      <form onSubmit={onSubmit}>
        <Row>
          {product.product_variation.map((variation) => (
            <Col key={variation.id} xl={12}>
              <div className="mt-3 border-bottom border-black-50 border-2">
                <h6 className="fs-16 mb-3 ms-1">{variation.name}</h6>

                <div className="d-flex flex-wrap gap-1">
                  {variation.value_type === "radio" &&
                    variation.product_variation_info.map((values) => (
                      <FormGroup
                        className="form-radio-danger p-0"
                        key={values.id}
                        name={variation.name}
                        check
                        onChange={onChange}
                        id={values.value}
                      >
                        <Label className="fs-8 me-2">
                          <Input
                            id={values.value}
                            className="me-2 ms-1"
                            type="radio"
                            name={variation.name}
                            value={values.value}
                            extra-price={values.extra_price}
                          />
                          {values.value}
                        </Label>
                      </FormGroup>
                    ))}
                  {variation.value_type === "select" && (
                    <Input
                      className="form-select-sm w-auto mb-2"
                      type="select"
                      onChange={onChange}
                      name={variation.name}
                      value=""
                    >
                      <option disabled value="">
                        Select {variation.name}
                      </option>
                      {variation.product_variation_info.map((values) => (
                        <option
                          key={values.id}
                          extra-price={values.extra_price}
                          value={values.value}
                        >
                          {values.value}
                        </option>
                      ))}
                    </Input>
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="mt-2 align-items-center justify-content-start w-100">
          <Col sm={6} xs={12}>
            <CustomButton
              btnClasses="rounded-pill mb-2 m-auto"
              color="primary"
              text="Add to Order"
            ></CustomButton>
          </Col>
          <Col sm={6} xs={12}>
            <div className="input-step step-primary mb-2 ">
              <button
                type="button"
                className="minus"
                onClick={() => {
                  countDown();
                }}
              >
                –
              </button>
              <Input
                type="number"
                className="product-quantity "
                value={qty}
                min="0"
                max="100"
                readOnly
              />
              <button
                type="button"
                className="plus"
                onClick={() => {
                  countUp();
                }}
              >
                +
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default ProductVarations;
