import React from "react";
import TopBar from "../../components/restaurant/navbar/Navbar";

function RestaurantLayout({ children }) {
  return (
    <div id="layout-wrapper">
      <TopBar />

      {children}
    </div>
  );
}

export default RestaurantLayout;
