import React from "react";
import CustomModel from "../../common/customModel/CustomModel";

import { Link } from "react-router-dom";
import { UncontrolledCollapse, Card, CardHeader } from "reactstrap";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

function FilterProductModel({ reset, onChange, filter }) {
  return (
    <CustomModel
      btnText={<i className="bx bx-slider fs-22"></i>}
      btnColor="primary"
      title="Product Filter"
      btnSize="sm"
      closeFooter={true}
      footerCloseText="Done"
    >
      <Card>
        <CardHeader>
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <h5 className="fs-16">Filters</h5>
            </div>
            <div className="flex-shrink-0">
              <Link
                to="#"
                onClick={reset}
                className="text-decoration-underline"
              >
                Clear All
              </Link>
            </div>
          </div>
        </CardHeader>

        <div className="accordion accordion-flush">
          {/* <div className="card-body border-bottom">
            <div>
              <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                Category
              </p>
              <ul className="list-unstyled mb-0 filter-list">
                <li>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="all"
                      value=""
                      name="category"
                      onChange={onChange}
                      checked={filter.category == ""}
                    />
                    <label className="form-check-label" htmlFor="all">
                      All
                    </label>
                  </div>
                </li>
                {data.map((category) => (
                  <li key={category.id}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={category.name}
                        value={category.name}
                        name="category"
                        onChange={onChange}
                        checked={filter.category == category.name}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={category.name}
                      >
                        {category.name}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}

          <div className="card-body border-bottom">
            <p className="text-muted text-uppercase fs-12 fw-medium mb-5">
              Price
            </p>

            <Nouislider
              range={{ min: 0, max: 2000 }}
              tooltips={true}
              start={[0, 2000]}
              connect
              onChange={(e) =>
                onChange({ target: { name: "price", value: e } })
              }
            />
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button bg-transparent shadow-none collapsed"
                type="button"
                id="flush-headingDiscount"
              >
                <span className="text-muted text-uppercase fs-12 fw-medium">
                  Discount
                </span>
              </button>
            </h2>
            <UncontrolledCollapse toggler="#flush-headingDiscount">
              <div
                id="flush-collapseDiscount"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body text-body pt-1">
                  <div className="d-flex flex-column gap-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="productdiscountRadio4"
                        name="discount"
                        value="all"
                        onChange={onChange}
                        checked={filter.discount == "all"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="productdiscountRadio4"
                      >
                        All
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="productdiscountRadio6"
                        name="discount"
                        value="yes"
                        onChange={onChange}
                        checked={filter.discount == "yes"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="productdiscountRadio6"
                      >
                        With Discount
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="productdiscountRadio5"
                        name="discount"
                        value="no"
                        onChange={onChange}
                        checked={filter.discount == "no"}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="productdiscountRadio5"
                      >
                        WithOut Discount
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </UncontrolledCollapse>
          </div>
        </div>
      </Card>
    </CustomModel>
  );
}

export default FilterProductModel;
