import React from "react";
import { Routes, Route } from "react-router-dom";
import { useGetAllCouponsQuery } from "../../../redux/couponSlice/couponSlice";
import CouponList from "./CouponList";

export default function Category() {
  const { data, isLoading } = useGetAllCouponsQuery();

  return (
    <div className="page-content">
      <Routes>
        <Route
          path="/"
          element={<CouponList coupons={data} isLoading={isLoading} />}
        />
        {/* <Route
          path=":id/edit"
          element={<EditCategory />}
          isLoading={isLoading}
        /> */}
      </Routes>
    </div>
  );
}
