import { toast } from "react-toastify";

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function filePreview(file) {
  const filePreview = Object.assign(file, {
    preview: URL.createObjectURL(file),
    formattedSize: formatBytes(file.size),
  });
  return filePreview;
}

export function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "_");
}

export function ratingToPrecent(rating) {
  let star_1 = 0,
    star_2 = 0,
    star_3 = 0,
    star_4 = 0,
    star_5 = 0;

  if (rating) {
    const total_stars = rating.reduce((accumulator, currentValue) => {
      accumulator += currentValue.rate;
      if (currentValue.rate === 1) star_1 += 1;
      if (currentValue.rate === 2) star_2 += 1;
      if (currentValue.rate === 3) star_3 += 1;
      if (currentValue.rate === 4) star_4 += 1;
      if (currentValue.rate === 5) star_5 += 1;

      return accumulator;
    }, 0);

    return [
      {
        star: "5",
        class: "success",
        count: star_5,
        width: (star_5 * 100) / rating.length,
      },
      {
        star: "4",
        class: "success",
        count: star_4,
        width: (star_4 * 100) / rating.length,
      },
      {
        star: "3",
        class: "success",
        count: star_3,
        width: (star_3 * 100) / rating.length,
      },
      {
        star: "2",
        class: "warning",
        count: star_2,
        width: (star_2 * 100) / rating.length,
      },
      {
        star: "1",
        class: "danger",
        count: star_1,
        width: (star_1 * 100) / rating.length,
      },
    ];
  }
  return [];
}

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export function productFiler(products, filter) {
  if (products)
    return products.filter((product) => {
      if (filter.discount === "all") {
        return (
          product.title.includes(filter.name) &&
          product.category.includes(filter.category) &&
          parseFloat(product.price) >= filter.price[0] &&
          parseFloat(product.price) <= filter.price[1] &&
          parseFloat(product.average_rating).toFixed(2) >= filter.rating
        );
      } else if (filter.discount === "yes") {
        return (
          product.title.includes(filter.name) &&
          product.category.includes(filter.category) &&
          parseFloat(product.price) >= filter.price[0] &&
          parseFloat(product.price) <= filter.price[1] &&
          parseFloat(product.average_rating).toFixed(2) >= filter.rating &&
          product.discount_price !== null
        );
      } else {
        return (
          product.title.includes(filter.name) &&
          product.category.includes(filter.category) &&
          parseFloat(product.price) >= filter.price[0] &&
          parseFloat(product.price) <= filter.price[1] &&
          parseFloat(product.average_rating).toFixed(2) >= filter.rating &&
          product.discount_price === null
        );
      }
    });
}

export function getAPIUrl() {
  let baseUrl;
  const location = window.location;
  if (location.host.includes("localhost")) {
    baseUrl = `${location.protocol}//${location.hostname}:8000/`;
  } else {
    baseUrl = `${location.protocol}//${location.host}/`;
  }

  return baseUrl;
}

export const toastOnError = (error) => {
  if (error.response) {
    if (error.response.data.non_field_errors) {
      toast.error(JSON.stringify("email or password invalid"));
    } else if (error.response.data.password) {
      toast.error(JSON.stringify("password invalid"));
    } else if (error.response.data.email) {
      toast.error(JSON.stringify("invalid email"));
    } else if (error.response.data.username) {
      toast.error(JSON.stringify("invalid username"));
    }
    // known error
    // toast.error(JSON.stringify(error.response.data));
  } else if (error.message) {
    toast.error(JSON.stringify(error.message));
  } else {
    toast.error(JSON.stringify(error));
  }
};
