import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  UncontrolledDropdown,
} from "reactstrap";
import { useDeleteCategoryMutation } from "../../../redux/categorySlice/categorySlice";
import DeleteModal from "../../common/deleteModel/DeleteModal";

function CategoryTable({ categorys }) {
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [dele, setDele] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [product, setProduct] = useState(null);
  const [deleteCategory] = useDeleteCategoryMutation();

  const onClickDelete = (product) => {
    setProduct(product);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (product) {
      deleteCategory(product.id);
      setDeleteModal(false);
    }
  };

  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element");
    ele.forEach((element) => {
      deleteCategory(element.value);
      del.style.display = "none";
      console.log(element);
    });
  };

  // const displayDelete = () => {
  //   const ele = document.querySelectorAll(".productCheckBox:checked");
  //   const del = document.getElementById("selection-element");
  //   setDele(ele.length);
  //   if (ele.length === 0) {
  //     del.style.display = "none";
  //   } else {
  //     del.style.display = "block";
  //   }
  // };

  const ListItem = ({ data, subCategory }) => (
    <ListGroupItem>
      <div className="d-flex align-items-center pagi-list">
        {/* <div className="me-4">
          <input
            type="checkbox"
            className="productCheckBox"
            value={data.id}
            onClick={() => displayDelete()}
          />
        </div> */}
        <div className="flex-shrink-0 me-3">
          <div>
            <img
              className="avatar-xs rounded-circle"
              alt=""
              src={data.category_image}
            />
          </div>
        </div>

        <div className="flex-grow-1 overflow-hidden">
          <h5 className="fs-13 mb-1 ">
            <Link
              to={`/admin/categorys/${data.slug}/edit`}
              className="link text-dark"
            >
              {data.name}
            </Link>
          </h5>
          <p className="born timestamp text-muted mb-0">
            {subCategory && `Parent -- ${data.parent}`}
          </p>
        </div>

        <div className="flex-shrink-0 ms-2">
          <UncontrolledDropdown>
            <DropdownToggle
              href="#"
              className="btn-soft-secondary btn-sm"
              tag="button"
            >
              <i className="ri-more-fill" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <Link to={`/admin/categorys/${data.slug}/edit`}>
                <DropdownItem>
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                  Edit
                </DropdownItem>
              </Link>

              <DropdownItem divider />
              <DropdownItem
                href="#"
                onClick={() => {
                  const categoryData = data;
                  onClickDelete(categoryData);
                }}
              >
                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </ListGroupItem>
  );

  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />

      <div>
        <div className="card">
          <div className="card-header border-0">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="card-title mb-0 flex-grow-1">All Category</h4>
              </div>
              <div className="col-auto">
                <div id="selection-element">
                  <div className="my-n1 d-flex align-items-center text-muted">
                    Select{" "}
                    <div
                      id="select-content"
                      className="text-body fw-semibold px-1"
                    >
                      {dele}
                    </div>{" "}
                    Result{" "}
                    <button
                      type="button"
                      className="btn btn-link link-danger p-0 ms-3"
                      onClick={() => setDeleteModalMulti(true)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="mx-n3">
              <ListGroup className="list mb-0" flush>
                {categorys &&
                  categorys.map((item) => (
                    <React.Fragment key={item.id}>
                      <ListItem data={item} />

                      {item?.children.map((child) => (
                        <ListItem
                          key={child.id}
                          data={child}
                          subCategory={true}
                        />
                      ))}
                    </React.Fragment>
                  ))}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryTable;
