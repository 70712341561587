import React from "react";
import { Link } from "react-router-dom";

const ProductInTable = ({ item }) => {
  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="avatar-sm bg-light rounded p-1 me-2">
            <img src={item.img} alt="" className="img-fluid d-block" />
          </div>
          <div>
            <h5 className="fs-14 my-1">
              <Link to="/apps-ecommerce-product-details" className="text-reset">
                {item.label}
              </Link>
            </h5>
            <span className="text-muted">{item.date}</span>
          </div>
        </div>
      </td>
      <td>
        <h5 className="fs-14 my-1 fw-normal">${item.price}</h5>
        <span className="text-muted">Price</span>
      </td>
      <td>
        <h5 className="fs-14 my-1 fw-normal">{item.orders}</h5>
        <span className="text-muted">Orders</span>
      </td>
      <td>
        <h5 className="fs-14 my-1 fw-normal">
          {item.stock ? (
            item.stock
          ) : (
            <span className="badge badge-soft-danger">Out of stock</span>
          )}{" "}
        </h5>
        <span className="text-muted">Stock</span>
      </td>
      <td>
        <h5 className="fs-14 my-1 fw-normal">${item.amount}</h5>
        <span className="text-muted">Amount</span>
      </td>
    </tr>
  );
};

export default ProductInTable;
