import Category from "../pages/Dashboard/category";
import Coupons from "../pages/Dashboard/coupons";

import AdminHome from "../pages/Dashboard/home";
import Products from "../pages/Dashboard/products";
import Checkout from "../pages/restaurant/checkout/Checkout";
import RestaurantHome from "../pages/restaurant/home/Home";
import ProductDetail from "../pages/restaurant/product-detail/ProductDetail";

export const dashboardRoutes = [
  { path: "/", component: <AdminHome /> },
  { path: "products/*", component: <Products /> },
  { path: "categorys/*", component: <Category /> },
  { path: "coupons/*", component: <Coupons /> },
];

export const restaurantRoutes = [
  { path: "/", component: <RestaurantHome /> },
  { path: ":slug/", component: <ProductDetail /> },
  { path: "checkout/", component: <Checkout /> },
];
