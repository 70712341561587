import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const getSiteSetting = createAsyncThunk(
  "siteSetting",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`profile/website/`);

      return response.data;
    } catch (e) {
      return rejectWithValue(e.response?.data?.error);
    }
  }
);

const initialState = {
  siteSetting: [],
  loading: false,
  faill: false,
  error: null,
  current_currency: "",
};
const SiteSettingSlice = createSlice({
  name: "siteSetting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSiteSetting.pending, (state, action) => {
        state.loading = true;
        state.faill = false;
      })
      .addCase(getSiteSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.siteSetting = action.payload;
        localStorage.setItem("site", JSON.stringify(action.payload));
        if (!state.current_currency)
          state.current_currency = action.payload.currency;
      })
      .addCase(getSiteSetting.rejected, (state, action) => {
        state.loading = false;
        state.faill = true;
        state.error = action.payload;
      });
  },
});

export default SiteSettingSlice.reducer;
