import React from "react";
import FilterNotFound from "../../common/filter-notfound/FilterNotFound";
import TableContainer from "../../common/tableContainer/TableContainer";

function CustomTable({ data, columns, customPageSize = 10, ...rest }) {
  return (
    <div className="card-body pt-0">
      {data && data.length > 0 ? (
        <TableContainer
          columns={columns}
          data={data || []}
          customPageSize={customPageSize}
          divClass="table-responsive mb-1"
          tableClass="mb-0 align-middle table-borderless"
          theadClass="table-light text-muted"
          {...rest}
        />
      ) : (
        <FilterNotFound />
      )}
    </div>
  );
}

export default CustomTable;
