import React from "react";
import { Link } from "react-router-dom";

function EmptyCard() {
  return (
    <div className="text-center empty-cart" id="empty-cart">
      <div className="avatar-md mx-auto my-3">
        <div className="avatar-title bg-soft-info text-info fs-36 rounded-circle">
          <i className="bx bx-cart"></i>
        </div>
      </div>
      <h5 className="mb-3">Your Cart is Empty!</h5>
      <Link to="/" className="btn btn-success w-md mb-3">
        Shop Now
      </Link>
    </div>
  );
}

export default EmptyCard;
