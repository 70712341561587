import React from "react";
import { Routes, Route } from "react-router-dom";

import DashboardPages from "../pages/DashboardPages";
import BasicSignIn from "../pages/login";
import RestaurantPages from "../pages/RestaurantPages";
import RequireAuthAdmin from "../utils/RequireAuthAdmin";

const Index = () => {
  return (
    <Routes>
      <Route
        path="admin/*"
        element={
          <RequireAuthAdmin>
            <DashboardPages />
          </RequireAuthAdmin>
        }
      />
      <Route path="/*" element={<RestaurantPages />} />

      <Route path="/login" element={<BasicSignIn />} />
    </Routes>
  );
};

export default Index;
