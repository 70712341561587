import React, { useState } from "react";
import {
  Badge,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";

import SimpleBar from "simplebar-react";

import { useSelector } from "react-redux";
import SmallCartCard from "../cards/SmallCartCard";
import EmptyCard from "../../restaurant/cart-empty/EmptyCard";

const MyCartDropdown = () => {
  const { cart, total_price, cartLenght } = useSelector((state) => state.cart);
  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );
  const [isCartDropdown, setIsCartDropdown] = useState(false);

  const toggleCartDropdown = () => {
    setIsCartDropdown(!isCartDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isCartDropdown}
        toggle={toggleCartDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle type="button" tag="button" className="btn p-1">
          <Badge
            color="primary"
            className="d-flex align-items-center rounded-pill px-2"
          >
            <span>
              {total_price} {current_currency}
            </span>
            <i className=" bx bx-cart-alt fs-22"></i>
            <span className="position-absolute cartitem-badge topbar-badge fs-9 translate-middle badge rounded-pill bg-info">
              {cartLenght}
              <span className="visually-hidden">unread messages</span>
            </span>
          </Badge>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
          aria-labelledby="page-header-cart-dropdown"
        >
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fs-16 fw-semibold"> My Cart</h6>
              </Col>
              <div className="col-auto">
                <span className="badge badge-soft-warning fs-13">
                  <span className="cartitem-badge"> {cartLenght} </span> items
                </span>
              </div>
            </Row>
          </div>
          <SimpleBar style={{ maxHeight: "300px" }}>
            <div className="p-2">
              {cartLenght > 0 ? (
                cart.map((item, key) => (
                  <SmallCartCard item={item} key={item.id} />
                ))
              ) : (
                <EmptyCard />
              )}
            </div>
          </SimpleBar>
          <div
            className="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border"
            id="checkout-elem"
          >
            <div className="d-flex justify-content-between align-items-center pb-3">
              <h5 className="m-0 text-muted">Total:</h5>
              <div className="px-2">
                <h5 className="m-0">
                  <span id="cart-item-total">{total_price}</span>{" "}
                  {current_currency}
                </h5>
              </div>
            </div>

            <Link to="/checkout" className="btn btn-primary text-center w-100">
              Checkout
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default MyCartDropdown;
