import React from "react";
import { Card, CardBody } from "reactstrap";

function CategoryCard({ category, state, onChange, subCategory }) {
  const getIsActive = () => {
    const is_sup = category?.children?.filter((item) => item.name === state);

    if (state === category.name || is_sup?.[0]) return true;
    return false;
  };

  return (
    <Card className="ribbon-box overflow-hidden category-card" role="button">
      <CardBody
        className={`text-center  ${getIsActive() ? "active" : null}`}
        onClick={() =>
          onChange({ target: { name: "category", value: category.name || "" } })
        }
      >
        {!subCategory && (
          <img
            className="mb-2 rounded-circle object-fit-cover"
            src={category.category_image}
            alt=""
            height="60"
            width="60"
          />
        )}
        <h6 className="mb-0 ">{category.name || "All"}</h6>
      </CardBody>
    </Card>
  );
}

export default React.memo(CategoryCard);
