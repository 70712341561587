import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  layoutModeTypes,
  layoutPositionTypes,
  layoutTypes,
  layoutWidthTypes,
  leftsidbarSizeTypes,
  leftSidebarImageTypes,
  leftSidebarTypes,
  leftSidebarViewTypes,
  topbarThemeTypes,
} from "../../utils/layout";

function changeHTMLAttribute(attribute, value) {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
}

function setLayoutStorge(state) {
  localStorage.setItem("layout", JSON.stringify(state));
}
const initialState = {
  layoutType: layoutTypes.VERTICAL,
  leftSidebarType: leftSidebarTypes.DARK,
  layoutModeType: layoutModeTypes.LIGHTMODE,
  layoutWidthType: layoutWidthTypes.FLUID,
  layoutPositionType: layoutPositionTypes.FIXED,
  topbarThemeType: topbarThemeTypes.LIGHT,
  leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
  leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
  leftSidebarImageType: leftSidebarImageTypes.NONE,
  productColumnsView: "towcolumn",
};

const LayoutReducer = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeLayoutTheme(state, action) {
      try {
        if (action.payload === "twocolumn") {
          document.documentElement.removeAttribute("data-layout-width");
        } else if (action.payload === "horizontal") {
          document.documentElement.removeAttribute("data-sidebar-size");
        }
        changeHTMLAttribute("data-layout", action.payload);
        state.layoutType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // c
      }
    },
    changeLayoutMode(state, action) {
      try {
        changeHTMLAttribute("data-layout-mode", action.payload);
        state.layoutModeType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeLeftSidebarTheme(state, action) {
      try {
        changeHTMLAttribute("data-sidebar", action.payload);
        state.leftSidebarType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeLayoutWidth(state, action) {
      try {
        if (action.payload === "lg") {
          changeHTMLAttribute("data-layout-width", "fluid");
        } else {
          changeHTMLAttribute("data-layout-width", "boxed");
        }
        state.layoutWidthType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeLayoutPosition(state, action) {
      try {
        changeHTMLAttribute("data-layout-position", action.payload);
        state.layoutPositionType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeTopbarTheme(state, action) {
      try {
        changeHTMLAttribute("data-topbar", action.payload);
        state.topbarThemeType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeSidebarImageType(state, action) {
      try {
        changeHTMLAttribute("data-sidebar-image", action.payload);
        state.leftSidebarImageType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeLeftsidebarSizeType(state, action) {
      try {
        switch (action.payload) {
          case "lg":
            changeHTMLAttribute("data-sidebar-size", "lg");
            break;
          case "md":
            changeHTMLAttribute("data-sidebar-size", "md");
            break;
          case "sm":
            changeHTMLAttribute("data-sidebar-size", "sm");
            break;
          case "sm-hover":
            changeHTMLAttribute("data-sidebar-size", "sm-hover");
            break;
          default:
            changeHTMLAttribute("data-sidebar-size", "lg");
        }
        state.leftsidbarSizeType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeLeftSidebarViewType(state, action) {
      try {
        changeHTMLAttribute("data-layout-style", action.payload);
        state.leftSidebarViewType = action.payload;
        setLayoutStorge(state);
      } catch (error) {
        // console.log(error);
      }
    },
    changeProductColumnsView(state, action) {
      state.productColumnsView = action.payload;
      setLayoutStorge(state);
    },
    reset(state, action) {
      return initialState;
    },
    setFromLocalStorge(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export default LayoutReducer.reducer;

export const {
  changeLayoutMode,
  changeLayoutPosition,
  changeLayoutTheme,
  changeLayoutWidth,
  changeLeftSidebarTheme,
  changeLeftSidebarViewType,
  changeLeftsidebarSizeType,
  changeSidebarImageType,
  changeTopbarTheme,
  reset,
  setFromLocalStorge,
  changeProductColumnsView,
} = LayoutReducer.actions;
