import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
function VerticalCard({ product }) {
  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );
  return (
    <Card className="rounded shadow-none card-bg ">
      <img
        className="card-img-top img-fluid min-h-100"
        src={product.product_image}
        alt="Card cap"
      />
      <CardBody className="p-2">
        <h4 className="card-title mb-2 text-dark ">{product.title}</h4>
        <p className="card-text fs-10">{product.side_description}</p>
        <span></span>
        <div className="text-start fw-bold">
          {product.discount_price ? (
            <>
              <del className="text-muted fs-6 me-1 text-primary">
                {product.price}
              </del>
              {product.discount_price}
            </>
          ) : (
            product.price
          )}
          {current_currency}
        </div>

        <Link to={`/${product.slug}`} className="stretched-link"></Link>
      </CardBody>
    </Card>
  );
}

export default VerticalCard;
