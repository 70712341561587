import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import _, { isEmpty } from "lodash";
import { toast } from "react-toastify";

import axiosInstance from "../../utils/axiosInstance";

export const ApplyCoupon = createAsyncThunk(
  "ApplyCoupon",
  async (coupon_code, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `coupon/coupon_valid/?coupon=${coupon_code}`
      );
      return response.data;
    } catch (e) {
      if (e.response.data) {
        toast.error(e.response?.data[0]);
        return rejectWithValue(e.response?.data);
      }
    }
  }
);

const initialState = {
  loading: false,
  cart: [],
  error: null,
  cartLenght: 0,
  total_price: 0,
  coupon: null,
};

const cartReducer = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      if (state.cart.length === 0) {
        state.cart.push(action.payload);
      } else {
        const isExist = state.cart.filter(
          (cartItem) => cartItem.id === action.payload.id
        )[0];
        if (isExist) {
          const isSameVar = isExist.data.filter((vars) =>
            _.isEqual(vars, action.payload.data[0])
          )[0];
          if (isSameVar) {
            isExist.data.map((vars, idx) => {
              if (_.isEqual(vars, action.payload.data[0])) {
                isExist.pricePerQty[idx] = action.payload.pricePerQty[0];
                isExist.qtyPerVars[idx] = action.payload.qtyPerVars[0];
              }
            });
          } else {
            if (action.payload.data.length > 0) {
              isExist.data.push(action.payload.data[0]);
              isExist.pricePerQty.push(action.payload.pricePerQty[0]);
              isExist.qtyPerVars.push(action.payload.qtyPerVars[0]);
            } else {
              isExist.pricePerQty[0] = action.payload.pricePerQty[0];
              isExist.qtyPerVars[0] = action.payload.qtyPerVars[0];
            }
          }
        } else {
          state.cart.push(action.payload);
        }
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
      cartReducer.caseReducers.getCartTotal(state, action);
      cartReducer.caseReducers.getCartLength(state, action);
    },
    removeFromCart(state, action) {
      state.cart.map((item, idx) => {
        if (action.payload.id === item.id) {
          if (item.data.length === 1 || item.data.length === 0) {
            return state.cart.splice(idx, 1);
          } else {
            item.data.map((vars, idx) => {
              if (_.isEqual(vars, action.payload.data)) {
                item.data.splice(idx, 1);
                item.pricePerQty.splice(idx, 1);
                item.qtyPerVars.splice(idx, 1);
                return item;
              }
              return item;
            });
          }
        }
        return state;
      });
      localStorage.setItem("cart", JSON.stringify(state.cart));
      cartReducer.caseReducers.getCartTotal(state, action);
      cartReducer.caseReducers.getCartLength(state, action);
    },
    setCart(state, action) {
      state.cart = action.payload;
      cartReducer.caseReducers.getCartTotal(state, action);
      cartReducer.caseReducers.getCartLength(state, action);
    },
    getCartTotal(state, action) {
      let count = 0.0;
      if (state.cart)
        state.cart.map((item) => {
          item.pricePerQty.map(
            (vars, idx) =>
              (count += item.pricePerQty[idx] * item.qtyPerVars[idx])
          );
        });
      if (state.coupon) {
        if (state.coupon.coupon_type === "Fixed") {
          state.total_price = count - state.coupon.value;
        } else if (state.coupon.coupon_type === "Percentage") {
          const discount_precent = state.coupon.value / 100;
          const discount_count = discount_precent * state.total_price;
          state.total_price -= discount_count;
        }
      } else {
        state.total_price = count;
      }
    },
    getCartLength(state, action) {
      let count = 0;

      if (state.cart)
        state.cart.map((item) => {
          item.pricePerQty.map((vars, idx) => (count += 1));

          return state;
        });

      state.cartLenght = count;
    },
  },
  extraReducers: (bulider) => {
    bulider.addCase(ApplyCoupon.pending, (state, action) => {
      state.coupon = null;
      cartReducer.caseReducers.getCartTotal(state, action);
    });
    bulider.addCase(ApplyCoupon.fulfilled, (state, action) => {
      state.coupon = action.payload;
      cartReducer.caseReducers.getCartTotal(state, action);
    });
  },
});

export default cartReducer.reducer;
export const { addToCart, setCart, removeFromCart, getCartTotal } =
  cartReducer.actions;
