import React from "react";

function FilterNotFound() {
  return (
    <div className="py-4 text-center">
      <div>
        <lord-icon
          src="https://cdn.lordicon.com/msoeawqm.json"
          background="#000"
          trigger="loop"
          colors="primary:#405189,secondary:#0ab39c"
          style={{ height: "75px", width: "75px" }}
        ></lord-icon>
      </div>

      <div className="mt-4">
        <h5>Sorry! No Result Found</h5>
      </div>
    </div>
  );
}

export default FilterNotFound;
