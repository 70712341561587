import React, { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

function SwiperWithThumbs({ mainImage, images }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="product-img-slider sticky-side-div">
      <Swiper
        navigation={true}
        spaceBetween={20}
        thumbs={{ swiper: thumbsSwiper }}
        className="swiper product-thumbnail-slider p-2 rounded bg-light"
      >
        <div className="swiper-wrapper">
          <SwiperSlide>
            <img src={mainImage} alt="" className="img-fluid d-block" />
          </SwiperSlide>
          {images &&
            images.map((img) => (
              <SwiperSlide key={img.id}>
                <img src={img.image} alt="" className="img-fluid d-block" />
              </SwiperSlide>
            ))}
        </div>
      </Swiper>

      <div className="product-nav-slider mt-2">
        <Swiper
          onSwiper={setThumbsSwiper}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          spaceBetween={10}
          className="swiper product-nav-slider mt-2 overflow-hidden"
        >
          <div className="swiper-wrapper">
            <SwiperSlide className="rounded">
              <div className="nav-slide-item">
                <img
                  src={mainImage}
                  alt=""
                  className="img-fluid d-block rounded"
                />
              </div>
            </SwiperSlide>
            {images &&
              images.map((img) => (
                <SwiperSlide key={img.id}>
                  <div className="nav-slide-item">
                    <img
                      src={img.image}
                      alt=""
                      className="img-fluid d-block rounded"
                    />
                  </div>
                </SwiperSlide>
              ))}
          </div>
        </Swiper>
      </div>
    </div>
  );
}

export default SwiperWithThumbs;
