import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const navigate = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isProducts, setIsProducts] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Products") {
      setIsProducts(false);
    }
  }, [navigate, iscurrentState, isDashboard, isProducts]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "ri-dashboard-2-line",
      link: "/admin/",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "products",
      label: "Products",
      icon: "ri-apps-2-line",
      link: "/admin/# ",
      click: function (e) {
        e.preventDefault();
        setIsProducts(!isProducts);
        setIscurrentState("Products");
        updateIconSidebar(e);
      },
      stateVariables: isProducts,
      subItems: [
        {
          id: "product-list",
          label: "Products",
          link: "/admin/products",
          parentId: "products",
        },
        {
          id: "add-product",
          label: "Add Product",
          link: "/admin/products/add-product",
          parentId: "products",
        },
        {
          id: "category",
          label: "Categorys",
          link: "/admin/categorys",
          parentId: "products",
        },
      ],
    },
    {
      id: "coupon",
      label: "coupons",
      icon: "ri-coupon-2-fill",
      link: "/admin/coupons",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

    // {
    //   id: "charts",
    //   label: "Charts",
    //   icon: "ri-pie-chart-line",
    //   link: "/#",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsCharts(!isCharts);
    //     setIscurrentState("Charts");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isCharts,
    //   subItems: [
    //     {
    //       id: "apexcharts",
    //       label: "Apexcharts",
    //       link: "/#",
    //       isChildItem: true,
    //       click: function (e) {
    //         e.preventDefault();
    //         setIsApex(!isApex);
    //       },
    //       stateVariables: isApex,
    //       childItems: [
    //         { id: 1, label: "Line", link: "/charts-apex-line" },
    //         { id: 2, label: "Area", link: "/charts-apex-area" },
    //         { id: 3, label: "Column", link: "/charts-apex-column" },
    //         { id: 4, label: "Bar", link: "/charts-apex-bar" },
    //         { id: 5, label: "Mixed", link: "/charts-apex-mixed" },
    //         { id: 6, label: "Timeline", link: "/charts-apex-timeline" },
    //         { id: 7, label: "Candlstick", link: "/charts-apex-candlestick" },
    //         { id: 8, label: "Boxplot", link: "/charts-apex-boxplot" },
    //         { id: 9, label: "Bubble", link: "/charts-apex-bubble" },
    //         { id: 10, label: "Scatter", link: "/charts-apex-scatter" },
    //         { id: 11, label: "Heatmap", link: "/charts-apex-heatmap" },
    //         { id: 12, label: "Treemap", link: "/charts-apex-treemap" },
    //         { id: 13, label: "Pie", link: "/charts-apex-pie" },
    //         { id: 14, label: "Radialbar", link: "/charts-apex-radialbar" },
    //         { id: 15, label: "Radar", link: "/charts-apex-radar" },
    //         { id: 16, label: "Polar Area", link: "/charts-apex-polar" },
    //       ],
    //     },
    //     {
    //       id: "chartjs",
    //       label: "Chartjs",
    //       link: "/charts-chartjs",
    //       parentId: "charts",
    //     },
    //     {
    //       id: "echarts",
    //       label: "Echarts",
    //       link: "/charts-echarts",
    //       parentId: "charts",
    //     },
    //   ],
    // },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
