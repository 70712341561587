import React from "react";
import { useSelector } from "react-redux";
import { Col, Fade, Row } from "reactstrap";
import HorizontalCard from "../../common/cards/HorizontalCard";
import VerticalCard from "../../common/cards/VerticalCard";
import FilterNotFound from "../../common/filter-notfound/FilterNotFound";
import Loading from "../../common/loading/Loading";

function ProductLargeView({ product, isLoading }) {
  const layout = useSelector((state) => state.Layout.productColumnsView);

  if (isLoading || !product) return <Loading />;
  if (product.length === 0) return <FilterNotFound />;
  return (
    <Row className="gx-1">
      {layout === "towcolumn" && (
        <>
          {product.map((item) => (
            <Col md={4} sm={6} xs={12} key={item.id}>
              <Fade>
                <VerticalCard product={item} />
              </Fade>
            </Col>
          ))}
        </>
      )}
      {layout === "onecolumn" && (
        <>
          {product.map((item) => (
            <Col xs={12} md={6} key={item.id}>
              <Fade>
                <HorizontalCard product={item} />
              </Fade>
            </Col>
          ))}
        </>
      )}
    </Row>
  );
}

export default ProductLargeView;
