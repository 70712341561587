import React, { useState } from "react";

import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form,
  CloseButton,
} from "reactstrap";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import { Link, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { filePreview } from "../../../utils/Utils";
import {
  useDeleteProductGalleryMutation,
  useEditProductMutation,
  useGetAllProductsQuery,
} from "../../../redux/productsSlice/productsSlice";
import { useGetAllCategoryQuery } from "../../../redux/categorySlice/categorySlice";
import AddCategory from "../../../components/dashboard/add_category/AddCategory";
import AddVarations from "../../../components/dashboard/add_varations/AddVarations";
import VariationForm from "../../../components/dashboard/add_varations/VariationForm";
import { useEditeProductVarationMutation } from "../../../redux/varationSlice/varationSlice";
import { useSelector } from "react-redux";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function EditProduct() {
  const { id } = useParams();

  const { product } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      product: data?.find((product) => product.slug === id),
    }),
  });
  const [deleteGalleryImage] = useDeleteProductGalleryMutation();

  const [editeProduct, { isLoading }] = useEditProductMutation();

  const [editeProductVaration, { isLoading: isEditVarationLoading }] =
    useEditeProductVarationMutation();

  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );
  const [selectedFiles, setselectedFiles] = useState([]);

  const [productImage, setProductImage] = useState(null);

  const [customActiveTab, setcustomActiveTab] = useState("1");

  const onProductImageChange = (e) => {
    setProductImage(e.target.files[0]);
  };

  const { data = [] } = useGetAllCategoryQuery();

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  function handleAcceptedFiles(files) {
    setselectedFiles(files);
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: product?.title,
      description: product?.description,
      side_description: product?.side_description,
      price: product?.price,
      category: product?.category,
      discount_price: product?.discount_price,
      meta_title: product?.meta_title,
      meta_keyword: product?.meta_keyword,
      sku: product?.sku,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter a Product Title"),
      description: Yup.string().required("Please Enter a Product description"),
      price: Yup.string().required("Please Enter a Product Price"),
      category: Yup.string().required("Please Enter a Product category"),
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("side_description", values.side_description);
      //   formData.append("slug", convertToSlug(values.title));
      formData.append("description", values.description);
      formData.append("price", values.price);

      formData.append("category", values.category);
      formData.append("sku", values.sku);
      if (productImage) {
        formData.append("product_image", productImage);
      }
      if (values.discount_price) {
        formData.append("discount_price", values.discount_price);
      }
      if (selectedFiles.length > 0)
        selectedFiles.map((item) => formData.append("product_gallery", item));
      editeProduct({ id: product.id, body: formData }).then(() => {
        validation.resetForm();
        setProductImage(null);
        setselectedFiles([]);
      });
    },
  });
  return (
    <Container fluid>
      <BreadCrumb
        title={product?.title}
        pageTitle="Products"
        link={`/admin/products/${product?.slug}/`}
      />
      <Row>
        <Col lg={8}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Card>
              <CardBody>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Product Title
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="product-title-input"
                    placeholder="Enter product title"
                    name="title"
                    value={validation.values.title || ""}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.title && validation.touched.title
                        ? true
                        : false
                    }
                  />
                  {validation.errors.title && validation.touched.title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <div>
                    <Label htmlFor="product_description" className="form-label">
                      Product Description
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="product_description"
                      rows="3"
                      placeholder="Enter Product Description"
                      name="description"
                      value={validation.values.description || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        validation.errors.description &&
                        validation.touched.description
                          ? true
                          : false
                      }
                    ></Input>
                    {validation.errors.description &&
                    validation.touched.description ? (
                      <FormFeedback type="invalid">
                        {validation.errors.description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="product-title-input">
                    Product Small Description
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="product-side_description-input"
                    placeholder="Enter product Small Description"
                    name="side_description"
                    value={validation.values.side_description || ""}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.errors.side_description &&
                      validation.touched.side_description
                        ? true
                        : false
                    }
                  />
                  {validation.errors.side_description &&
                  validation.touched.side_description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.side_description}
                    </FormFeedback>
                  ) : null}
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Product Gallery</h5>
              </CardHeader>
              <CardBody>
                <div className="mb-4">
                  <h5 className="fs-14 mb-1">Product Image</h5>
                  <p className="text-muted">Add Product main Image.</p>
                  <div className="text-center">
                    <div className="position-relative d-inline-block">
                      <div className="position-absolute top-100 start-100 translate-middle">
                        <label
                          htmlFor="product-image-input"
                          className="mb-0"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title=""
                          data-bs-original-title="Select Image"
                        >
                          <div className="avatar-xs">
                            <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                              <i className="ri-image-fill"></i>
                            </div>
                          </div>
                        </label>
                        <input
                          className="form-control d-none"
                          defaultValue=""
                          id="product-image-input"
                          type="file"
                          onChange={onProductImageChange}
                          accept="image/png, image/gif, image/jpeg"
                        />
                      </div>
                      <div className="avatar-lg">
                        <div className="avatar-title bg-light rounded">
                          <img
                            src={
                              productImage
                                ? filePreview(productImage).preview
                                : product?.product_image
                            }
                            id="product-img"
                            alt=""
                            className="avatar-md h-auto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h5 className="fs-14 mb-1">Product Gallery</h5>
                  <p className="text-muted">Add Product Gallery Images.</p>

                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          </div>
                          <h5>Drop files here or click to upload.</h5>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="list-unstyled mb-0" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      const file = filePreview(f);
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={file.name}
                                  src={file.preview}
                                />
                              </Col>
                              <Col className="col-auto">
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {file.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{file.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                    {product?.product_gallery.map((image) => (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={image.id}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={product?.title}
                                src={image.image}
                              />
                            </Col>

                            <Col>
                              <CloseButton
                                color="danger"
                                onClick={() =>
                                  deleteGalleryImage({
                                    imageId: image.id,
                                    productId: product.id,
                                  })
                                }
                              />
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      General Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      Meta Data
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={customActiveTab}>
                  <TabPane id="addproduct-general-info" tabId="1">
                    <Row>
                      <Col sm={3}>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="product-price-input"
                          >
                            Price
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="product-price-addon"
                            >
                              {current_currency}
                            </span>
                            <Input
                              type="text"
                              className="form-control"
                              id="product-price-input"
                              placeholder="Enter price"
                              name="price"
                              aria-label="Price"
                              aria-describedby="product-price-addon"
                              value={validation.values.price || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.price &&
                                validation.touched.price
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.price &&
                            validation.touched.price ? (
                              <FormFeedback type="invalid">
                                {validation.errors.price}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>

                      <Col sm={3}>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="product-Discount-input"
                          >
                            Discount Proce
                          </label>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="product-Discount-addon"
                            >
                              {current_currency}
                            </span>
                            <Input
                              type="text"
                              className="form-control"
                              id="product-Discount-input"
                              placeholder="Enter Discount"
                              name="discount_price"
                              aria-label="discount_price"
                              aria-describedby="product-orders-addon"
                              value={validation.values.discount_price || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.discount_price &&
                                validation.touched.discount_price
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.discount_price &&
                            validation.touched.discount_price ? (
                              <FormFeedback type="invalid">
                                {validation.errors.discount_price}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>

                      <Col sm={3}>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="product-sku-input"
                          >
                            Sku
                          </label>
                          <div className="input-group mb-3">
                            <Input
                              type="text"
                              className="form-control"
                              id="product-sku-input"
                              placeholder="Enter Sku"
                              name="sku"
                              aria-label="sku"
                              aria-describedby="product-sku"
                              value={validation.values.sku || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.sku && validation.touched.sku
                                  ? true
                                  : false
                              }
                            />
                            {validation.errors.sku && validation.touched.sku ? (
                              <FormFeedback type="invalid">
                                {validation.errors.sku}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane id="addproduct-metadata" tabId="2">
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="meta-title-input"
                          >
                            Meta title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter meta title"
                            id="meta-title-input"
                            name="meta_title"
                            value={validation.values.meta_title || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.meta_title &&
                              validation.touched.meta_title
                                ? true
                                : false
                            }
                          />
                          {validation.errors.meta_title &&
                          validation.touched.meta_title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.meta_title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="meta-keywords-input"
                          >
                            Meta Keywords
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter meta keywords"
                            id="meta-keywords-input"
                            name="meta_keyword"
                            value={validation.values.meta_keyword || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.meta_keyword &&
                              validation.touched.meta_keyword
                                ? true
                                : false
                            }
                          />
                          {validation.errors.meta_keyword &&
                          validation.touched.meta_keyword ? (
                            <FormFeedback type="invalid">
                              {validation.errors.meta_keyword}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <Label
                        className="form-label"
                        htmlFor="meta-description-input"
                      >
                        Meta Description
                      </Label>
                      <textarea
                        className="form-control"
                        id="meta-description-input"
                        placeholder="Enter meta description"
                        name="meta_description"
                        rows="3"
                      ></textarea>
                    </div>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
            <div className="text-end mb-3">
              <button
                disabled={isLoading}
                type="submit"
                className="btn btn-success w-sm"
              >
                Submit
              </button>
            </div>
          </Form>
        </Col>
        <Col lg={4}>
          <Card>
            <CardHeader>
              <h5 className="card-title mb-0">View on Site</h5>
            </CardHeader>
            <CardBody>
              <Link
                to={`/${product?.slug}/`}
                id="TooltipTop"
                className="btn btn-success ms-1 w-100"
              >
                <i className="ri-eye-fill align-bottom m-1"></i> View on site
              </Link>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h5 className="card-title mb-0">Product Categories</h5>
            </CardHeader>
            <CardBody>
              <p className="text-muted mb-2 d-flex justify-content-between align-items-center">
                Select product category
                <AddCategory />
              </p>

              <Input
                name="category"
                type="select"
                className="form-select"
                id="category-field"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.category || ""}
              >
                {data.map((item, key) => (
                  <React.Fragment key={item.id}>
                    <option value={item.name}>{item.name}</option>
                    {item?.children.map((child) => (
                      <option
                        className="ms-1 text-light"
                        value={child.name}
                        key={child.id}
                      >
                        -- {child.name}
                      </option>
                    ))}
                  </React.Fragment>
                ))}
              </Input>
              {validation.touched.category && validation.errors.category ? (
                <FormFeedback type="invalid">
                  {validation.errors.category}
                </FormFeedback>
              ) : null}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h5 className="card-title mb-0 d-flex justify-content-between align-items-center">
                Product Varation <AddVarations id={product?.id} />
              </h5>
            </CardHeader>
            <CardBody>
              {product?.product_variation.map((item) => (
                <VariationForm
                  key={item.id}
                  callback={editeProductVaration}
                  variationToEdit={item}
                  productId={product?.id}
                  isLoading={isEditVarationLoading}
                />
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default EditProduct;
