import React from "react";
import { Col } from "reactstrap";

import CardWithTable from "../../../components/common/cards/CardWithTable";
import OrderInTable from "../../../components/dashboard/product_in_table/OrderInTable";

const RecentOrders = () => {
  return (
    <React.Fragment>
      <Col xl={6}>
        <CardWithTable title="Recent Orders" link="#">
          {(recentOrders || []).map((item, key) => (
            <OrderInTable key={key} item={item} />
          ))}
        </CardWithTable>
      </Col>
    </React.Fragment>
  );
};

export default RecentOrders;

const recentOrders = [
  {
    id: 1,
    orderId: "#VZ2112",
    name: "Alex Smith",
    product: "Clothes",
    amount: 109.0,
    vendor: "Zoetic Fashion",
    status: "Paid",
    statusClass: "success",
    rating: 5,
    votes: "61",
  },
  {
    id: 2,
    orderId: "#VZ2111",
    name: "Jansh Brown",
    product: "Kitchen Storage",
    amount: 149.0,
    vendor: "Micro Design",
    status: "Pending",
    statusClass: "warning",
    rating: 4.5,
    votes: "61",
  },
  {
    id: 3,
    orderId: "#VZ2109",
    name: "Ayaan Bowen",
    product: "Bike Accessories",
    amount: 215.0,
    vendor: "Nesta Technologies",
    status: "Paid",
    statusClass: "success",
    rating: 4.9,
    votes: "89",
  },
  {
    id: 4,
    orderId: "#VZ2108",
    name: "Prezy Mark",
    product: "Furniture",
    amount: 199.0,
    vendor: "Syntyce Solutions",
    status: "Unpaid",
    statusClass: "danger",
    rating: 4.3,
    votes: "47",
  },
  {
    id: 5,
    orderId: "#VZ2107",
    name: "Vihan Hudda",
    product: "Bags and Wallets",
    amount: 330.0,
    vendor: "iTest Factory",
    status: "Paid",
    statusClass: "success",
    rating: 4.7,
    votes: "161",
  },
];
