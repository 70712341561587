import React from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import Loading from "../../../components/common/loading/Loading";
import NotFound from "../../../components/common/notfound/NotFound";
import ProductVarations from "../../../components/restaurant/product-varations/ProductVarations";
import ProductIntro from "../../../components/restaurant/product_intro/ProductIntro";
import { useGetAllProductsQuery } from "../../../redux/productsSlice/productsSlice";

function ProductDetail() {
  const { slug } = useParams();
  const { product, isLoading } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      product: data?.find((post) => post.slug === slug),
      isLoading,
    }),
  });

  if (isLoading) return <Loading />;

  if (!isLoading && !product) return <NotFound url="/" />;

  return (
    <main className="bg-white restaurant-detail">
      <div className="page-content">
        <div className="d-xs-flex d-sm-none">
          <Container fluid>
            <BreadCrumb pageTitle="Home" title={product?.title} link="/" />
            <Row className="">
              <Col sm={12} md={6}>
                <ProductIntro product={product} />
              </Col>
              <Col sm={12} md={6}>
                <ProductVarations product={product} />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="d-none d-sm-flex flex-column">
          <Container fluid>
            <BreadCrumb pageTitle="Home" title={product?.title} link="/" />
          </Container>
          <Container>
            <Row className="">
              <Col sm={12} md={6}>
                <ProductIntro product={product} />
              </Col>
              <Col sm={12} md={6}>
                <ProductVarations product={product} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </main>
  );
}

export default ProductDetail;
