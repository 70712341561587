import React from "react";

import RestaurantLayout from "../layouts/restaurantLayout/RestaurantLayout";
import { Routes, Route } from "react-router-dom";
import { restaurantRoutes } from "../Routes/Routes";
function RestaurantPages() {
  return (
    <RestaurantLayout>
      <Routes>
        {restaurantRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.component}
          ></Route>
        ))}
      </Routes>
    </RestaurantLayout>
  );
}

export default RestaurantPages;
