import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import FilterProduct from "../../../components/product_filter/FilterProduct";
import ProductTable from "../../../components/dashboard/product_table/ProductTable";
import { useGetAllProductsQuery } from "../../../redux/productsSlice/productsSlice";
import { productFiler } from "../../../utils/Utils";

function ProductList() {
  const { data, isLoading } = useGetAllProductsQuery();
  const [filter, setFilter] = useState({
    name: "",
    price: [0, 1000],
    rating: 0,
    category: "",
    discount: "all",
  });

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const reset = () => {
    setFilter({
      name: "",
      price: [0, 1000],
      rating: 0,
      category: "",
      discount: "all",
    });
  };
  const result = productFiler(data, filter);

  return (
    <Container fluid>
      <BreadCrumb title="Products" pageTitle="Home" link="/admin" />
      <Row>
        <Col xl={3} lg={4}>
          <FilterProduct filter={filter} onChange={onChange} reset={reset} />
        </Col>
        <Col xl={9} lg={8}>
          <ProductTable products={result} loading={isLoading} />
        </Col>
      </Row>
    </Container>
  );
}

export default ProductList;
