import React from "react";
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModel from "../../common/customModel/CustomModel";
import Flatpickr from "react-flatpickr";
function AddCoupon({ updateObj, callback, onSubmit, ...props }) {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      coupon_type: updateObj ? updateObj.coupon_type : "",
      coupon_code: updateObj ? updateObj.coupon_code : "",
      value: updateObj ? updateObj.value : "",
      descraption: updateObj ? updateObj.descraption : "",
      valid_until: updateObj ? updateObj.valid_until : null,
    },
    validationSchema: Yup.object({
      coupon_type: Yup.string().required("Please select Coupon Type"),
      coupon_code: Yup.string().required("Please Coupon Code"),
      value: Yup.string().required("Please Enter Coupon Value"),
    }),
    onSubmit: (values) => {
      const couponData = {
        coupon_type: values.coupon_type,
        coupon_code: values.coupon_code,
        value: values.value,
        descraption: values.descraption,
        valid_until: values.valid_until,
        id: updateObj?.id,
      };
      onSubmit(validation, couponData);
      callback();
      console.log(couponData);
    },
  });
  return (
    <CustomModel
      {...props}
      customToggle={callback}
      btnColor={"success"}
      btnClasses="view-btn"
      btnSize="large"
      btnText={
        <>
          <i className="ri-add-fill me-1 align-bottom"></i> Add Coupon
        </>
      }
      title={updateObj ? "Update Coupon" : "Add New Coupon"}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="coupon_type" className="form-label">
                Coupon Type
              </Label>
              <Input
                type="select"
                className="form-control"
                id="coupon_type"
                placeholder="Select Coupob Type"
                name="coupon_type"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.coupon_type || ""}
                invalid={
                  validation.touched.coupon_type &&
                  validation.errors.coupon_type
                    ? true
                    : false
                }
              >
                <option>Select Coupon Type</option>
                <option value="Fixed">Fixed Price</option>
                <option value="Percentage">Percentage (%) Price</option>
              </Input>
              {validation.touched.coupon_type &&
              validation.errors.coupon_type ? (
                <FormFeedback type="invalid">
                  {validation.errors.coupon_type}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label htmlFor="coupon_code" className="form-label">
                Coupon Code
              </Label>
              <Input
                type="text"
                className="form-control"
                id="coupon_code"
                placeholder="Enter Coupon Code"
                name="coupon_code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.coupon_code || ""}
                invalid={
                  validation.touched.coupon_code &&
                  validation.errors.coupon_code
                    ? true
                    : false
                }
              />
              {validation.touched.coupon_code &&
              validation.errors.coupon_code ? (
                <FormFeedback type="invalid">
                  {validation.errors.coupon_code}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label htmlFor="value" className="form-label">
                Coupon Value
              </Label>
              <Input
                type="text"
                className="form-control"
                id="value"
                placeholder="Coupon Value"
                name="value"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.value || ""}
                invalid={
                  validation.touched.value && validation.errors.value
                    ? true
                    : false
                }
              />
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label htmlFor="descraption" className="form-label">
                Descraption
              </Label>
              <Input
                type="text"
                className="form-control"
                id="descraption"
                placeholder="Coupon Descraption"
                name="descraption"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.descraption || ""}
                invalid={
                  validation.touched.descraption &&
                  validation.errors.descraption
                    ? true
                    : false
                }
              />
              {validation.touched.descraption &&
              validation.errors.descraption ? (
                <FormFeedback type="invalid">
                  {validation.errors.descraption}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label htmlFor="valid_until" className="form-label">
                Valid Until
              </Label>
              <Flatpickr
                id="valid_until"
                placeholder="Coupon valid_until"
                name="valid_until"
                onChange={(date, dateStr) =>
                  validation.setFieldValue("valid_until", dateStr)
                }
                onBlur={validation.handleBlur}
                value={validation.values.valid_until || ""}
                className="form-control"
                options={{
                  enableTime: true,
                  dateFormat: "Y-m-d H:i:S",
                }}
              />

              {validation.touched.valid_until &&
              validation.errors.valid_until ? (
                <FormFeedback type="invalid">
                  {validation.errors.valid_until}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              <button type="submit" className="btn btn-success">
                {updateObj ? "Update Coupon" : "Add Coupon"}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </CustomModel>
  );
}

export default AddCoupon;
