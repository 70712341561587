import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledCollapse, Card, CardHeader } from "reactstrap";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import { useGetAllCategoryQuery } from "../../redux/categorySlice/categorySlice";
function FilterProduct({ filter, onChange, reset }) {
  const { data = [], isLoading } = useGetAllCategoryQuery();
  return (
    <Card>
      <CardHeader>
        <div className="d-flex mb-3">
          <div className="flex-grow-1">
            <h5 className="fs-16">Filters</h5>
          </div>
          <div className="flex-shrink-0">
            <Link to="#" onClick={reset} className="text-decoration-underline">
              Clear All
            </Link>
          </div>
        </div>
      </CardHeader>

      <div className="accordion accordion-flush">
        <div className="card-body border-bottom">
          <div>
            <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
              Product Name
            </p>
            <ul className="list-unstyled mb-0 filter-list">
              <li>
                <div className="search-box search-box-sm">
                  <input
                    type="text"
                    className="form-control bg-light border-0"
                    name="name"
                    value={filter.name}
                    onChange={onChange}
                    placeholder="Search Product Name..."
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="card-body border-bottom">
          <div>
            <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
              Category
            </p>
            <ul className="list-unstyled mb-0 filter-list">
              <li>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="all"
                    value=""
                    name="category"
                    onChange={onChange}
                    checked={filter.category == ""}
                  />
                  <label className="form-check-label" htmlFor="all">
                    All
                  </label>
                </div>
              </li>
              {data.map((category) => (
                <React.Fragment key={category.id}>
                  <li>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={category.name}
                        value={category.name}
                        name="category"
                        onChange={onChange}
                        checked={filter.category == category.name}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={category.name}
                      >
                        {category.name}
                      </label>
                    </div>
                  </li>
                  {category?.children.map((child) => (
                    <li key={child.id}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={child.name}
                          value={child.name}
                          name="category"
                          onChange={onChange}
                          checked={filter.category == child.name}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={child.name}
                        >
                          {child.name}
                        </label>
                      </div>
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>

        <div className="card-body border-bottom">
          <p className="text-muted text-uppercase fs-12 fw-medium mb-5">
            Price
          </p>

          <Nouislider
            range={{ min: 0, max: 2000 }}
            tooltips={true}
            start={[0, 2000]}
            connect
            onChange={(e) => onChange({ target: { name: "price", value: e } })}
          />
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button bg-transparent shadow-none collapsed"
              type="button"
              id="flush-headingRating"
            >
              <span className="text-muted text-uppercase fs-12 fw-medium">
                Rating
              </span>
            </button>
          </h2>

          <UncontrolledCollapse toggler="#flush-headingRating">
            <div
              id="flush-collapseRating"
              className="accordion-collapse collapse show"
              aria-labelledby="flush-headingRating"
            >
              <div className="accordion-body text-body">
                <div className="d-flex flex-column gap-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productratingRadio4"
                      name="rating"
                      value={4}
                      onChange={onChange}
                      checked={filter.rating == 4}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productratingRadio4"
                    >
                      <span className="text-muted">
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star"></i>
                      </span>{" "}
                      4 & Above
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productratingRadio3"
                      name="rating"
                      value={3}
                      onChange={onChange}
                      checked={filter.rating == 3}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productratingRadio3"
                    >
                      <span className="text-muted">
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                      </span>{" "}
                      3 & Above
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productratingRadio2"
                      name="rating"
                      value={2}
                      onChange={onChange}
                      checked={filter.rating == 2}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productratingRadio2"
                    >
                      <span className="text-muted">
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                      </span>{" "}
                      2 & Above
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productratingRadio1"
                      name="rating"
                      value={1}
                      onChange={onChange}
                      checked={filter.rating == 1}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productratingRadio1"
                    >
                      <span className="text-muted">
                        <i className="mdi mdi-star text-warning"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                      </span>{" "}
                      1 & Above
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productratingRadio0"
                      name="rating"
                      value={0}
                      onChange={onChange}
                      checked={filter.rating == 0}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productratingRadio0"
                    >
                      <span className="text-muted">
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                        <i className="mdi mdi-star"></i>
                      </span>{" "}
                      0
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </UncontrolledCollapse>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button bg-transparent shadow-none collapsed"
              type="button"
              id="flush-headingDiscount"
            >
              <span className="text-muted text-uppercase fs-12 fw-medium">
                Discount
              </span>
            </button>
          </h2>
          <UncontrolledCollapse toggler="#flush-headingDiscount">
            <div
              id="flush-collapseDiscount"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body text-body pt-1">
                <div className="d-flex flex-column gap-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productdiscountRadio4"
                      name="discount"
                      value="all"
                      onChange={onChange}
                      checked={filter.discount == "all"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productdiscountRadio4"
                    >
                      All
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productdiscountRadio6"
                      name="discount"
                      value="yes"
                      onChange={onChange}
                      checked={filter.discount == "yes"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productdiscountRadio6"
                    >
                      With Discount
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="productdiscountRadio5"
                      name="discount"
                      value="no"
                      onChange={onChange}
                      checked={filter.discount == "no"}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="productdiscountRadio5"
                    >
                      WithOut Discount
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </UncontrolledCollapse>
        </div>
      </div>
    </Card>
  );
}

export default FilterProduct;
