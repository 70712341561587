import React from "react";
import { Card, CardBody, Fade } from "reactstrap";

function Loading() {
  return (
    <Fade>
      <Card>
        <CardBody>
          <div className="py-4 text-center">
            <div>
              <lord-icon
                src="https://lottie.host/e1e3adb2-a07a-4669-913a-23a0e7fddb7c/yK8adEClvh.json"
                background="#000"
                trigger="loop"
                colors="primary:#405189,secondary:#0ab39c"
                style={{ height: "300px", width: "auto" }}
              ></lord-icon>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fade>
  );
}

export default Loading;
