import React from "react";
import SwiperWithThumbs from "../../common/swiper/SwiperWithThumbs";
import "./product_intro.styles.css";

function ProductIntro({ product }) {
  return (
    <div className="product-intro-container">
      <SwiperWithThumbs
        images={product.product_gallery}
        mainImage={product.product_image}
      />
      <h4 className="product-intro-title">{product.title}</h4>
      <p className="mb-4">{product.description}</p>
    </div>
  );
}

export default ProductIntro;
