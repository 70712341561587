import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import CountWidget from "../../../components/count-widget/CountWidget";
import Revenue from "../../../components/dashboard/dashboard-charts/Revenue";
import HeaderWithPicker from "../../../components/dashboard/header-with-picker/HeaderWithPicker";
import BestSellingProducts from "./BestSellingProduct";
import RecentOrders from "./RecentOrders";

function Dashboard() {
  document.title = "Dashboard";
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Dashboard" pageTitle="Home" link="/admin" />
        <Row>
          <Col>
            <div className="h-100">
              <HeaderWithPicker
                username={user.first_name || user.username}
                onPickerChange={(e) => console.log(e)}
              />
              <Row>
                {ecomWidgets.map((item, key) => (
                  <Col xl={3} md={6} key={key}>
                    <CountWidget item={item} />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <Revenue />
                </Col>
              </Row>
              <Row>
                <BestSellingProducts />
                <RecentOrders />
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;

const ecomWidgets = [
  {
    id: 1,
    cardColor: "primary",
    label: "Total Earnings",
    badge: "ri-arrow-right-up-line",
    badgeClass: "success",
    percentage: "+16.24",
    counter: "559.25",
    link: "View net earnings",
    bgcolor: "success",
    icon: "bx bx-dollar-circle",
    decimals: 2,
    prefix: "$",
    suffix: "k",
  },
  {
    id: 2,
    cardColor: "secondary",
    label: "Orders",
    badge: "ri-arrow-right-down-line",
    badgeClass: "danger",
    percentage: "-3.57",
    counter: "36894",
    link: "View all orders",
    bgcolor: "info",
    icon: "bx bx-shopping-bag",
    decimals: 0,
    prefix: "",
    separator: ",",
    suffix: "",
  },
  {
    id: 3,
    cardColor: "success",
    label: "Customers",
    badge: "ri-arrow-right-up-line",
    badgeClass: "success",
    percentage: "+29.08",
    counter: "183.35",
    link: "See details",
    bgcolor: "warning",
    icon: "bx bx-user-circle",
    decimals: 2,
    prefix: "",
    suffix: "M",
  },
  {
    id: 4,
    cardColor: "info",
    label: "My Balance",
    badgeClass: "muted",
    percentage: "+0.00",
    counter: "165.89",
    link: "Withdraw money",
    bgcolor: "primary",
    icon: "bx bx-wallet",
    decimals: 2,
    prefix: "$",
    suffix: "k",
  },
];
