import { api } from "../api";

export const varationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addProductVaration: builder.mutation({
      query: ({ id, data }) => ({
        url: "product/product_variations/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Products", id }],
    }),
    deleteProductVaration: builder.mutation({
      query: ({ id, varId }) => ({
        url: `product/product_variations/${varId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Products", id }],
    }),
    editeProductVaration: builder.mutation({
      query: ({ id, data, varId }) => ({
        url: `product/product_variations/${varId}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Products", id }],
    }),
    deleteSingleVarationValue: builder.mutation({
      query: (id) => ({
        url: `product/product_variation_info/${id}/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddProductVarationMutation,
  useDeleteProductVarationMutation,
  useEditeProductVarationMutation,
  useDeleteSingleVarationValueMutation,
} = varationApi;
