import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import SwiperWithThumbs from "../../../components/common/swiper/SwiperWithThumbs";
import Rating from "react-rating";
import PricingWidget from "../../../components/widgets/PricingWidget";
import { useGetAllProductsQuery } from "../../../redux/productsSlice/productsSlice";
import { ratingToPrecent } from "../../../utils/Utils";
import Loading from "../../../components/common/loading/Loading";
import NotFound from "../../../components/common/notfound/NotFound";
import { useSelector } from "react-redux";

function ProductDetail({ isLoading }) {
  const { id } = useParams();

  const { product } = useGetAllProductsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      product: data?.find((post) => post.slug === id),
    }),
  });

  const rating_width = useMemo(
    () => ratingToPrecent(product?.rating),
    [product?.rating]
  );
  const current_currency = useSelector(
    (state) => state.siteSetting.current_currency
  );
  if (isLoading) return <Loading />;

  if (!isLoading && !product) return <NotFound url="/dashboard" />;

  return (
    <Container fluid>
      <BreadCrumb
        title={product.title}
        pageTitle="Products"
        link="/admin/products"
      />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row className="gx-lg-5">
                <Col xl={4} md={8} className="mx-auto">
                  <SwiperWithThumbs
                    images={product.product_gallery}
                    mainImage={product.product_image}
                  />
                </Col>
                <Col xl={8}>
                  <div className="mt-xl-0 mt-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h4>{product.title}</h4>
                        <div className="hstack gap-3 flex-wrap">
                          <div>{product.category}</div>
                          <div className="vr"></div>

                          <div className="text-muted">
                            Published :{" "}
                            <span className="text-body fw-medium">
                              {new Date(product.created_at).toDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <div>
                          <Link
                            to={`/admin/products/${product.slug}/edit`}
                            id="TooltipTop"
                            className="btn btn-light"
                          >
                            <i className="ri-pencil-fill align-bottom m-1"></i>
                          </Link>
                          <Link
                            to={`/${product.slug}/`}
                            id="TooltipTop"
                            className="btn btn-light ms-1"
                          >
                            <i className="ri-eye-fill align-bottom m-1"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                      <div className="text-muted fs-16">
                        <Rating
                          initialRating={product.average_rating}
                          emptySymbol="mdi mdi-star-outline text-muted "
                          fullSymbol="mdi mdi-star text-warning "
                          readonly
                        />
                      </div>
                      <div className="text-muted">
                        ( {product.rating.length} Customer Review )
                      </div>
                    </div>
                    <Row className="mt-4">
                      <Col lg={3} sm={6}>
                        <PricingWidget
                          detail={`${current_currency} ${product.price}`}
                          label="Price"
                          icon="ri-money-dollar-circle-fill"
                        />
                      </Col>
                      <Col lg={3} sm={6}>
                        <PricingWidget
                          detail={product.sales_count}
                          label="Sales Count"
                          icon="ri-file-copy-2-fill"
                        />
                      </Col>
                      <Col lg={3} sm={6}>
                        <PricingWidget
                          label="Total Revenue"
                          icon="ri-inbox-archive-fill"
                        />
                      </Col>
                    </Row>
                    <Row>
                      {product.product_variation.map((variation) => (
                        <Col key={variation} xl={4}>
                          <div className=" mt-4">
                            <h5 className="fs-14">{variation.name} :</h5>
                            <div className="d-flex flex-wrap gap-2">
                              {variation.value_type === "radio" &&
                                variation.product_variation_info.map(
                                  (values) => (
                                    <FormGroup
                                      className=" form-radio-dark "
                                      key={values.id}
                                      check
                                    >
                                      <Input
                                        id={values.value}
                                        type="radio"
                                        name={variation.name}
                                        value={values.value}
                                      />
                                      <Label
                                        for={values.value}
                                        className="d-flex justify-content-center align-items-center"
                                      >
                                        {values.value}
                                      </Label>
                                    </FormGroup>
                                  )
                                )}
                              {variation.value_type === "select" && (
                                <>
                                  <Input
                                    className="form-select-sm w-auto"
                                    type="select"
                                    name={variation.name}
                                  >
                                    {variation.product_variation_info.map(
                                      (values) => (
                                        <option key={values.id}>
                                          {values.value}
                                        </option>
                                      )
                                    )}
                                  </Input>
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Description :</h5>
                      <p>{product.description}</p>
                    </div>
                    <div className="mt-5">
                      <div>
                        <h5 className="fs-14 mb-3">Ratings & Reviews</h5>
                      </div>
                      <Row className="gy-4 gx-0">
                        <Col lg={4}>
                          <div>
                            <div className="pb-3">
                              <div className="bg-light px-3 py-2 rounded-2 mb-2">
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1">
                                    <div className="fs-16 align-middle text-warning">
                                      <Rating
                                        initialRating={product.average_rating.toFixed(
                                          1
                                        )}
                                        emptySymbol="mdi mdi-star-outline text-muted "
                                        fullSymbol="mdi mdi-star text-warning "
                                        readonly
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <h6 className="mb-0">
                                      {product.average_rating.toFixed(1)} out of
                                      5
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center">
                                <div className="text-muted">
                                  Total{" "}
                                  <span className="fw-medium">
                                    {product.rating.length}{" "}
                                  </span>
                                  reviews
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={8}>
                          <div className="ps-lg-4">
                            <div className="mt-3">
                              {rating_width.map((rate) => (
                                <Row
                                  className="align-items-center g-2"
                                  key={rate.star}
                                >
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0">{rate.star} star</h6>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="p-2">
                                      <div className="progress animated-progess progress-sm">
                                        <div
                                          className={`progress-bar bg-${rate.class}`}
                                          role="progressbar"
                                          style={{ width: `${rate.width}%` }}
                                          aria-valuenow={rate.width}
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="p-2">
                                      <h6 className="mb-0 text-muted">
                                        {rate.count}
                                      </h6>
                                    </div>
                                  </div>
                                </Row>
                              ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductDetail;
