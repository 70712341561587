import React from "react";
import { Routes, Route } from "react-router-dom";
import { useGetAllProductsQuery } from "../../../redux/productsSlice/productsSlice";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import ProductDetail from "./ProductDetail";
import ProductList from "./ProductList";
function Products() {
  const { data, isLoading } = useGetAllProductsQuery();

  return (
    <div className="page-content">
      <Routes>
        <Route path="/" element={<ProductList />} />
        <Route path="add-product" element={<AddProduct />} />
        <Route path=":id" element={<ProductDetail isLoading={isLoading} />} />
        <Route
          path=":id/edit"
          element={<EditProduct />}
          isLoading={isLoading}
        />
      </Routes>
    </div>
  );
}

export default Products;
