import React from "react";

import CustomModel from "../../common/customModel/CustomModel";
import { useAddProductVarationMutation } from "../../../redux/varationSlice/varationSlice";
import VariationForm from "./VariationForm";
function AddVarations({ id }) {
  const [addVarations, { isLoading }] = useAddProductVarationMutation();

  return (
    <CustomModel btnText="Add New" btnColor="link" title="Add New Variation">
      <VariationForm
        callback={addVarations}
        isLoading={isLoading}
        productId={id}
      />
    </CustomModel>
  );
}

export default AddVarations;
