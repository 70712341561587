import React from "react";
import { useState } from "react";
import CategorySlider from "../../../components/restaurant/category-slider/CategorySlider";
import ProductView from "../../../components/restaurant/product-view/ProductView";

import TopBar from "../../../components/restaurant/top-bar/TopBar";
import { useGetAllProductsQuery } from "../../../redux/productsSlice/productsSlice";
import { productFiler } from "../../../utils/Utils";

function Home() {
  const [filter, setFilter] = useState({
    name: "",
    price: [0, 1000],
    rating: 0,
    category: "",
    discount: "all",
  });

  const { data, isLoading } = useGetAllProductsQuery();

  const onChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const reset = () => {
    setFilter({
      ...filter,
      name: "",
      price: [0, 1000],
      rating: 0,
      category: "",
      discount: "all",
    });
  };

  const result = productFiler(data, filter);

  return (
    <main className="resturant-home">
      <div className="page-content">
        <div className="container-sm">
          <TopBar onChange={onChange} state={filter} reset={reset} />
          <CategorySlider state={filter.category} onChange={onChange} />
          <ProductView product={result} isLoading={isLoading} />
        </div>
        {/* <div className="d-none d-sm-flex">
          <Container>
            <TopBar onChange={onChange} state={filter} reset={reset} />
            <div className="px-5 py-3">
              <CategorySlider state={filter.category} onChange={onChange} />
            </div>
            <ProductLargeView
              layout={filter.layout}
              product={result}
              isLoading={isLoading}
            />
          </Container>
        </div> */}
      </div>
    </main>
  );
}

export default Home;
