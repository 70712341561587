import React from "react";
import { toast } from "react-toastify";
import { Card, CardHeader, Container, Row } from "reactstrap";
import BreadCrumb from "../../../components/common/breadcrumb/BreadCrumb";
import AddCoupon from "../../../components/dashboard/add_coupon/AddCoupon";
import CouponTable from "../../../components/dashboard/coupon_table/CouponTable";
import { useAddCouponMutation } from "../../../redux/couponSlice/couponSlice";

function CouponList({ coupons, isLoading }) {
  const [AddCouponQuery] = useAddCouponMutation();

  const onAddSubmit = (validation, data) => {
    AddCouponQuery(data)
      .unwrap()
      .then((e) => {
        toast.success("Coupon Added..");
        validation.resetForm();
      })
      .catch((error) => {
        if (error.data) validation.setErrors(error.data);
      });
  };
  return (
    <Container fluid>
      <BreadCrumb title="Coupons" pageTitle="Home" link="/" />
      <Card>
        <CardHeader className="border-0 rounded">
          <Row className="g-2 justify-content-end">
            <div className="col-lg-auto">
              <div className="hstack gap-2">
                <AddCoupon onSubmit={onAddSubmit} />
              </div>
            </div>
          </Row>
        </CardHeader>
      </Card>
      <CouponTable data={coupons} />
    </Container>
  );
}

export default CouponList;
