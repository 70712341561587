import React, { useEffect } from "react";
import { Provider } from "react-redux";
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import { isEmpty } from "lodash";
import {
  setFromLocalStorge,
  changeLayoutMode,
} from "./layoutSlice/layoutSlice";
import { getSiteSetting } from "./siteSettingSlice/siteSettingSlice";
import { authSuccess, getCurrentUser } from "./authSlice/authSlice";
import { setCart } from "./cart/cartSlice";

let store;

const Root = ({ children, initialState = {} }, props) => {
  // const [getSiteProfile] = useGetSiteProfileQuery();

  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware),
    ...initialState,
  });

  if (!isEmpty(localStorage.getItem("layout"))) {
    const state = JSON.parse(localStorage.getItem("layout"));
    store.dispatch(setFromLocalStorge(state));
    store.dispatch(changeLayoutMode(state.layoutModeType));
  }

  if (!isEmpty(localStorage.getItem("token"))) {
    store.dispatch(authSuccess(localStorage.getItem("token")));
    store.dispatch(getCurrentUser());
  }
  if (!isEmpty(localStorage.getItem("cart"))) {
    store.dispatch(setCart(JSON.parse(localStorage.getItem("cart"))));
  }

  store.dispatch(getSiteSetting());

  // effect to update favicon
  useEffect(() => {
    if (!isEmpty(localStorage.getItem("site"))) {
      const site = JSON.parse(localStorage.getItem("site"));
      let link = document.querySelector("link[rel~='icon']");
      document.documentElement.style.setProperty(
        "--primary-color",
        site.main_color
      );

      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = site.image;
    }
  }, []);

  return <Provider store={store}> {children}</Provider>;
};

export { store };

export default Root;
