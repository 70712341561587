import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CardHeader } from "reactstrap";
import { ApplyCoupon } from "../../../redux/cart/cartSlice";

function ApplyCouponComponent() {
  const dispatch = useDispatch();
  const [coupoCode, setCouponCode] = useState("");
  const onApplyCoupon = (e) => {
    e.preventDefault();
    dispatch(ApplyCoupon(coupoCode));
  };

  return (
    <CardHeader className="bg-soft-light border-bottom-dashed">
      <div className="text-center">
        <h6 className="mb-2">
          Have a <span className="fw-semibold">promo</span> code ?
        </h6>
      </div>
      <form onSubmit={onApplyCoupon} className="hstack gap-3 px-3 mx-n3">
        <input
          className="form-control me-auto"
          type="text"
          placeholder="Enter coupon code"
          aria-label="Add Promo Code here..."
          name="cupon"
          value={coupoCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <button type="submit" className="btn btn-primary w-xs">
          Apply
        </button>
      </form>
    </CardHeader>
  );
}

export default ApplyCouponComponent;
